import { createContext, useContext, useState } from 'react';
import jwt from 'jwt-decode';

const UserContext = createContext<any>('');  // musi być any

/* 1 mimo że tam ustawia od razu użytkownika na null - tam trzeba poprawić, tj. od 
razu ustawiać stan początkowy zgodny z local storage - dokładnie tak pokazują testy 
    KONIECZNE JEST ZAŁADOWANIE STANU POCZĄTKOWEGO ZGODNEGO ZE STANEM LOCAL STORAGE
    jeśli się nie załaduje tego stanu to przejście na / wyświetla null na pasku, tj. zaloguj, a trasy private dalej 
    chodzą, bo w storage jest dobry token
   2 konieczne jest użycie user provider bo w Private są problemy z wywołaniem setState oraz
     można w przyszłości użyć też tych PublicRoute, które nie dopuszczają np. do formy logowania
   3 ustawienie '' jest lepsze niż null bo wtedy nie ma tego problemu z undefined i miganiem na pasku

/* STAN początkowy zmiennej user musi być ZGODNY Z LOCAL STORAGE */
/* ------------------------------------- */

// celem uniknięcia mignięcia - undefined, tj. wtedy nie wyświetlać nic

export default function UserProvider({ children }) {
    
    const tokenFromStorage = localStorage.getItem('token');

    let initialValue;

    // 1. BRAK TOKENA
    if (!tokenFromStorage) {   // nie zalogowany
        // USUNIĘCIE USER'A Z PASKA
        initialValue = '';
    } else { // jakiś token jest

       // sprawdzenie czy wygasły

       const { exp, login } = jwt<any>(tokenFromStorage);

       if (Date.now() >= (exp * 1000 - 30000)) {  // -30s, tak żeby nie było 401
        
        // USUNIĘCIE wygasłego TOKENA i zmiennej user
        localStorage.removeItem('token');  // konieczne
        initialValue = '';
       } else {  // token zupełnie ok, jest i nie wygasły
        initialValue = login;
       }
    }    

    const [user, setUser] = useState<any>(initialValue); // zmienna przechowująca zalogowanego użytkownika

    // najlepiej tutaj już by było ustawić odpowiednią wartość początkową,
    // tzn. najpierw undefined

    const logout = () => {
        setUser('');
        localStorage.removeItem('token');
        // return 'ok';
    };

    const checkToken = () => { // musi być tu bo chodzi o dostęp do setUser

        const tokenFromStorage = localStorage.getItem('token');
        
        // 1. BRAK TOKENA
        if (!tokenFromStorage) {   // nie zalogowany
            // USUNIĘCIE USER'A Z PASKA
            setUser('');
            return 0;
        }       

        // 2. TOKEN JEST, ALE WYGASŁ
        const { exp, login } = jwt<any>(tokenFromStorage);    //!

        if (Date.now() >= (exp * 1000 - 30000)) {  // -30s, tak żeby nie było 401
        
            // USUNIĘCIE wygasłego TOKENA i zmiennej user
            localStorage.removeItem('token');  // konieczne
            setUser('');
            return 1;
        }

        // 3. TOKEN JEST OK
        setUser(login);  // refresh case
        return 2;
    }
     
    
    return (
        <UserContext.Provider value={{user, setUser, logout, checkToken}}> 
            {children}
        </UserContext.Provider>
    );
}

export function useUser() {  // nazewnictwo jako hook
    return useContext(UserContext)
}
