/* wersja z tabelą, wersja z Gridem - test responsywności 
  jest w tej chwili jakaś responsywność, tzn. na tablet składa się w 2 kolumny,
  a na mobile w 1, ale wtedy potrzebne jest centrowanie, może fluid na image, albo wyświetlanie kart
  w tej chwili może zostać jak jest

  => wyświetlać ze zmiennych dummy data
  => dodać zapytanie axios oraz "map"
*/

import React, { Fragment, useState, useEffect } from 'react';

import { Container, Grid, Icon, Pagination } from 'semantic-ui-react'

import './SearchAdvsResults.css';

import { Advs } from '../../api/agent';
import IFoundCarAdvForDisplay from '../../models/foundCarAdvForDisplay';
import foundCarAdvsConverter from '../../models/convert/foundCarAdvsConverter';
import { useLocation } from 'react-router-dom';

import { Select } from 'semantic-ui-react'; // do sortowania

let debug_mode = false; if (process.env.REACT_APP_IS_PRODUCTION) debug_mode = false;

const sortOptions = [ // jego edycja wymaga edycji switcha w advs router
  { key: 1, value: 1, text: 'daty dodania' },
  { key: 2, value: 2, text: 'ceny rosnąco' },
  { key: 3, value: 3, text: 'ceny malejąco' },  
  { key: 4, value: 4, text: 'przebiegu rosnąco' },
  { key: 5, value: 5, text: 'przebiegu malejąco' },
  { key: 6, value: 6, text: 'rocznika malejąco' },
  { key: 7, value: 7, text: 'rocznika rosnąco' }
]

/*
const dummyAdvs = [
    {
      miniImgUrl: 'https://res.cloudinary.com/dtpjgv1zo/image/upload/v1681722741/mk/kk64evstawgfptajzgpb.jpg',
      producent: "Dacia",
      model: "Sandero",
      pojemnosc_silnika: 1.2,
      miejscowosc: "Chełm",
      przebieg: 120000,
      rok_produkcji: '',
      cena: 10000
    },
    {
      miniImgUrl: 'https://res.cloudinary.com/dtpjgv1zo/image/upload/v1681722741/mk/kk64evstawgfptajzgpb.jpg',
      producent: "Dacia",
      model: "Sandero",
      pojemnosc_silnika: 1.2,
      miejscowosc: "Chełm",
      przebieg: 120000,
      rok_produkcji: '',
      cena: 10000
    },
    {
      miniImgUrl: 'https://res.cloudinary.com/dtpjgv1zo/image/upload/v1681722741/mk/kk64evstawgfptajzgpb.jpg',
      producent: "Dacia",
      model: "Sandero",
      pojemnosc_silnika: 1.2,
      miejscowosc: "Chełm",
      przebieg: 120000,
      rok_produkcji: '',
      cena: 10000
    }
  ]
*/



const SearchAdvsResult = ({match, history}) => {

  const [advsArray, setAdvsArray] = useState<IFoundCarAdvForDisplay[]>([]); // wczytywana w useEffect z axios request

  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);

  const [ activePage, setActivePage ] = useState<number>(1);
  const [ totalPages, setTotalPages ] = useState<number>(1);

  const [sortSelectValue, setSortSelectValue] = useState<number>(1); // default value
     
  const convertGetData = (): any => { // KONWERSJA

    const query = match.params.query;

    // powrotna konwersja na obiekt
    const paramsObj = Object.fromEntries(new URLSearchParams(query));

    // console.log('obiekt paramsObj do konwersji:');
    // console.log(paramsObj);  

    // sprawdzenie obecności wymaganych właściwości:
    /*{
      "rodzaj_pojazdu": "1", //+ exists, required 
      "producent": "", //+ exists, not required
      "model": "", //+ exists, not required
      "stan_wyszukaj": "3", //+ exists, required    !(paramsObj.stan_wyszukaj || paramsObj.stan_wyszukaj == "")
      "dystans": "1000", //+ exists, required        !(paramsObj.dystans || paramsObj.dystans == "") 
      

      => "lokalizacja": 
        lat      // exists, not required    !(paramsObj.lat)
        lon      // exists, not required    !(paramsObj.lon)
        miasto   // exists, not required    !(paramsObj.miasto)
        kod      // exists, not required    !(paramsObj.kod)
  
      => "strona" // +exists, required !(paramsObj.strona || paramsObj.strona == "")

      !(paramsObj.)
      !(paramsObj. || paramsObj. == "")  

    }*/

    // tutaj dopisuje się nowe kryteria filtra, jeśli nie są opcjonalne

    // TEST PARAMETRÓW W URL-u  warunek "" - przepuszenie pustych wartości
    if ( 
      !(paramsObj.rodzaj_pojazdu) ||
      !(paramsObj.producent || paramsObj.producent === "") ||
      !(paramsObj.model || paramsObj.model === "") ||
      !(paramsObj.stan_wyszukaj || paramsObj.stan_wyszukaj === "") ||
      !(paramsObj.kolor || paramsObj.kolor === "") ||
      !(paramsObj.paliwo || paramsObj.paliwo === "") ||
      !(paramsObj.dystans || paramsObj.dystans === "") ||
      !(paramsObj.lat || paramsObj.lat === "") ||
      !(paramsObj.lon || paramsObj.lon === "") ||
      !(paramsObj.miasto || paramsObj.miasto === "") ||
      !(paramsObj.kod || paramsObj.kod === "") ||
      !(paramsObj.strona || paramsObj.strona === "") ||
      !(paramsObj.s) // parametr sortowania - musi być i mieć wartość
      ) {
      console.log('query param error');
      // przekierowanie error
      history.push({
        pathname: '/msg',
        state: {
          type: 'error',
          message: 'Błędny argument wywołania w adresie url'
        }
      });
      return null; // ! musi być też return po przekierowaniu
    } // param
    
    // UTWORZENIE WYSYŁANEGO OBIEKTU WYNIKOWEGO, Z KONWERSJĄ NA TYP NUMERYCZNY ( TJ. FIND AKCEPTUJE ZŁE TYPY, ALE NIE AGGREGATE Z SEARCH IN RADIUS )
    // bez dopisania tutaj nowych filtrów do API dojdzie undefined

    const resultObject: any = {
      rodzaj_pojazdu: parseInt(paramsObj.rodzaj_pojazdu, 10),
      producent: parseInt(paramsObj.producent, 10), // to jest opcjonalne pole formularza //
      model: paramsObj.model,
      stan_wyszukaj: parseInt(paramsObj.stan_wyszukaj, 10),

      kolor: parseInt(paramsObj.kolor, 10),
      paliwo: parseInt(paramsObj.paliwo, 10),

      strona: parseInt(paramsObj.strona, 10),

      dystans: parseInt(paramsObj.dystans, 10),
      lat: parseFloat(paramsObj.lat),
      lon: parseFloat(paramsObj.lon),
      miasto: paramsObj.miasto,
      kod: paramsObj.kod,
      s: parseInt(paramsObj.s, 10),
    }

    // ?? SPRAWDZENIE REQUIRED NaN na typach numerycznych, tzn. czy konwersja się udała, np. na page

    // USUNIĘCIE OPCJONALNYCH PÓL FORMULARZA Z OBIEKTU

    if (isNaN(resultObject.producent))  // "marka" z formularza
      delete resultObject.producent;

    if (isNaN(resultObject.kolor))
      delete resultObject.kolor;
    
    if (isNaN(resultObject.paliwo))
      delete resultObject.paliwo;

    if (resultObject.model === "*" || resultObject.model === "")
      delete resultObject.model;

    if ( (isNaN(resultObject.lat)) || (isNaN(resultObject.lon)) ) {
      delete resultObject.lat;
      delete resultObject.lon;
      delete resultObject.miasto;
      delete resultObject.kod;
      delete resultObject.dystans; // dystans też usuwany
    }
    
    // sprawdzenie danych sortowania // opcjonalnie

    // lat lub lon - cały obiekt lokalizcja
    
     // console.log("result object:");
     // console.log(resultObject);

    return resultObject;
  }

  // searchCarAdvs
  
  const fetchCarAdvs = async (queryObject) => {
    
    const advsFromDb = await Advs.searchCarAdvs(queryObject);  // object in / out
    
    if (debug_mode)
    {
      console.log('linia 188, advsFromDb:');
      console.log(advsFromDb);
    }


    const totalPages = advsFromDb[0].metadata[0].totalPages;
    const currentPage = advsFromDb[0].metadata[0].page;

    setTotalPages(totalPages);
    setActivePage(currentPage);

    // konwersja i załadowanie do tablicy //
    const convertedAdvs = foundCarAdvsConverter(advsFromDb[0].data);

    // console.log('tablica po konwersji:');
    // console.log(convertedAdvs);

    setIsDataLoading(false); // wyłączenie spinnera
    setAdvsArray(convertedAdvs);

  }

  const handleAdvClicked = (id: string) => {
    const path = '/advs/cars/view/' + id

    history.push({
      pathname: path,
    });
  }

  let location = useLocation(); // potrzebne do aktualizacji po zmianie url-a

  // zamiast ponownego wywołania fetch w pagination useEffect jest ponownie wywoływane //

  useEffect(() => {  // component did mount
    
    setIsDataLoading(true);
    setAdvsArray([]); // usunięcie z ekranu poprzednich ogłoszeń, np. po zmianie strony
        
    const queryObject = convertGetData();  // konwersja na OBIEKT Z URL-a, ze zmianą typów
    
    if (queryObject === null)  // samo push we funkcji nie wystarcza do przerwania wykonywania dalszego kodu
      return; // przeniesienie tutaj push też nie wystarczało

    // console.log('get object po konwersji z url:');
    // console.log(getObject); // jako tako przetestowane
    
    setSortSelectValue(queryObject.s); // aktualizacja wartości, jednak musi być przez useState
     
    fetchCarAdvs(queryObject)
     // make sure to catch any error           // tutaj jest lepiej
     .catch((error)=> {          // kwestia undefined jeszcze ..., na 404 mógłby być interceptor
       // obsluga bledu: konsola + toast
       console.log(error.response);
     
       history.push({
         pathname: '/msg',
         state: {
           type: 'error',
           message: error.response.data.message
         }
       });
 
       // ~ obsluga bledu
     });
 
   }, [ location ]);

   // rezygnacja z ładowania danych - jedynie zmiana url-a //
   const handlePaginationChange = (activePageArg: any) => {
    
    // ZMIANIE ULEGA TYLKO STRONA - 1. nadpisanie strony 2. push
    
    // 1. NADPISANIE STRONY 

    const query = match.params.query;
    // powrotna konwersja na obiekt
    const paramsObj = Object.fromEntries(new URLSearchParams(query));

    // nadpisanie numeru strony
    paramsObj.strona = activePageArg; 

    // konwersja na url
    const txtQuery = new URLSearchParams(paramsObj).toString();  // bez znaku ?

    // console.log('txtQuery:');
    // console.log(txtQuery);

    // PUSH
    const path = '/advs/cars/search/' + txtQuery;
    // przekierowanie parametryczne

    // console.log('przekierowanie:');
    // console.log(path);

    history.push({
      pathname: path
    });

   }

   // dodatkowo konieczne jest jeszcze wysłanie tych danych w obiekcie post
   const handleSortSelectChange = (selectedValue) => {
    // console.log('handle called');
    // console.log(arg);
  
    // pobranie obiektu url - dopisanie &s='' - history.push

    // ZMIANIE ULEGA TYLKO STRONA - 1. nadpisanie strony 2. push
    
    // 1. NADPISANIE STRONY 

    const query = match.params.query;
    // powrotna konwersja na obiekt
    const paramsObj = Object.fromEntries(new URLSearchParams(query));

    // nadpisanie numeru strony
    paramsObj.s = selectedValue; 

    // konwersja na url
    const txtQuery = new URLSearchParams(paramsObj).toString();  // bez znaku ?

    // console.log('txtQuery:');
    // console.log(txtQuery);

    // PUSH
    const path = '/advs/cars/search/' + txtQuery;
    // przekierowanie parametryczne

    // console.log('przekierowanie:');
    // console.log(path);

    history.push({
      pathname: path
    });
   
  }

  return (
    <Container>

      <Grid fluid="true" verticalAlign='middle'>

        <Grid.Row style={{margin: 30}} className="searchCriteriaRow">

          {!isDataLoading && totalPages > 0 && (
            <div style={{width: '100%', textAlign: 'center'}}>
              sortowanie według:&nbsp;&nbsp;
              <Select 
                className='semanticSelect' /* musiało być tu dodane bo nie jest FormikControl */
                placeholder='...'
                options={ sortOptions } 
                value={sortSelectValue}
                onChange={(e, data) => { handleSortSelectChange(data.value) } }
                style={{marginTop: 4}}
              />
            </div>
          )}
        </Grid.Row>
        
      {isDataLoading && (
        <div style={{width: '100%', textAlign: 'center'}}>
          <Icon loading name='circle notch' size='big' />
        </div>
      )}

      {advsArray.length === 0 && isDataLoading === false && (
        <div className="noContentMessage">
          <br/><br/>
          Brak dodanych ogłoszeń spełniających podane kryterium wyszukiwania
        </div>
      )}

      {advsArray.map((adv, i) => {
        return (                  
          <Grid.Row 
            style={{margin: "5px"}} 
            key={i}
            className="highlighted backgroundLight searchResultsRow" // klasy highlighted backgroundLight są od semantic-a
            onClick={()=>{handleAdvClicked(adv._id)}}
            // co drugi podświetlony
          >
          
          <Grid.Column computer="3" mobile="16" tablet="7">
            <img alt='fotografia' src={adv.miniImgUrl || "../../../assets/noPhotoMini.jpg"} style={{marginTop: '2px', paddingTop: '3px'}}></img>
          </Grid.Column>

          <Grid.Column computer="1"></Grid.Column>
          
          <Grid.Column computer="4" mobile="16" tablet="7" className="fontDesc">
            <span style={{fontWeight: 'bold'}}>{adv.producent}<br/>{adv.model}</span>&nbsp;{adv.pojemnosc_silnika.toFixed(1)}&nbsp;L<br />
            {adv.miejscowosc} {adv.dystans} 
          </Grid.Column>

          <Grid.Column computer="1"></Grid.Column> 
                  
          <Grid.Column computer="3" mobile="16" tablet="7" className="fontDesc">
            {adv.przebieg} km
            <br />
            {adv.rok_produkcji}
          </Grid.Column >

          <Grid.Column computer="1"></Grid.Column>
          
          <Grid.Column computer="3" mobile="16" tablet="7" className="fontDesc" style={{fontSize: 20}}>
            {adv.cena} zł
          </Grid.Column>                 
        {/* ~wiersz */}
        </Grid.Row>
        );
      })}       

      </Grid>
      {!isDataLoading && totalPages > 0 && (
        <div style={{width: '100%', textAlign: 'center', marginTop: '70px'}}>
          <Pagination 
            disabled={totalPages === 1}
            activePage={activePage}
            totalPages={totalPages}
            boundaryRange={1}
            siblingRange={1}       
            onPageChange={(e, { activePage })=>{ handlePaginationChange(activePage) }}
            size='mini'
          />
        </div> 
      )}
    </Container>
  )
}

export default SearchAdvsResult

/* dodanie nowego kryterium:
 - wymuszenie jego obecności
 - konwersja, tj. przygotowanie wysyłanego obiektu
*/