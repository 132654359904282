import { createContext, useContext, useState } from 'react';

const ThemeContext = createContext<any>('');  // musi być any
const ThemeUpdateContext = createContext<any>('');

export function useTheme() {
    return useContext(ThemeContext)
}

export function useUpdateTheme() {
    return useContext(ThemeUpdateContext)
}

// ================================

export default function ThemeProvider2({ children }) {
    
    // initial value wczytuje local storage w przypadku odświeżenia strony
    const [currentTheme, setCurrentTheme] = useState<any>(()=> { 
            const initialTheme=localStorage.getItem("selectedTheme") ?? 'dark';  // ustawienie default theme, ewent: 'light'
            document.querySelector("body")?.setAttribute('data-theme', initialTheme);
            return initialTheme;
        }); 

    /* stan, body, local storage */
    const updateTheme = (argTheme: string) => {
        document.querySelector("body")?.setAttribute('data-theme', argTheme);
        // ustawienie stanu i jego zapisanie
        setCurrentTheme(argTheme);
        localStorage.setItem("selectedTheme", argTheme);
    }
  
    // jako value może też iść FUNKCA / UPDATE FUNKCJA
      
    return (
        <ThemeContext.Provider value={currentTheme}>
            <ThemeUpdateContext.Provider value={updateTheme}>
                {children}
            </ThemeUpdateContext.Provider>
        </ThemeContext.Provider>
    );
}

/* children to elementy objęte tagiem */

