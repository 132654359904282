import car_brands from "../../common/data/select-options/car_brands";
import IFoundCarAdvForDisplay from "../foundCarAdvForDisplay";

interface IFoundCarAdvFromDb {
  _id: string, // do url
  smallPhotos: string[],
  producent: number,
  model: string,
  pojemnosc_silnika: number,
  miejsce: any,  // przepisany obiekt, zawierający props city oraz zip
  przebieg: number,
  rok_produkcji: number,
  cena: number,
  distance?: number // opcjonalne, bo liczone tylko przy search in radius
}

function addIntegerSeparators(x, separator: string): string {
  x += '';
  if (!separator) return x;
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x)) {
    x = x.replace(rgx, '$1' + separator + '$2');
  }
  return x;
}

const convertProducent = (arg: Number) => {
  const index = car_brands.findIndex((obj) => obj.value === arg);

  if (index === -1) // musi być też ten warunek, gdyby argument był błędny
    return '[!arg]';
    
  const object = car_brands[index];

  return object.text;
}

const foundCarAdvsConverter = (argArray: IFoundCarAdvFromDb[]) => {

  // to jest cała tablica tych ogłoszeń ! - musi być pętla

  const result: IFoundCarAdvForDisplay[] = [];  
  
  let object: IFoundCarAdvFromDb;

  
  for (object of argArray) {

    const miniImgUrlTmp = (object.smallPhotos.length > 0 ? object.smallPhotos[0] : "" );

    const distanceTmp = (object.distance && object.distance > 2 ? " - " + Math.round(object.distance) + " km" : "") // ewent. toFixed(1) warunek dodany gdyż dziwnie wygląda: wyszukaj Wrocław a później znajduje wrocław 0.2

    const convertedObject: IFoundCarAdvForDisplay = {
      _id: object._id, // do url
      miniImgUrl: miniImgUrlTmp,   
      producent: convertProducent(object.producent),
      model: object.model,
      pojemnosc_silnika: object.pojemnosc_silnika,
      miejscowosc: object.miejsce.city, // object.location.city,
      kod: object.miejsce.zip, // object.location.zip,
      przebieg: object.przebieg,
      rok_produkcji: object.rok_produkcji,
      cena: addIntegerSeparators(object.cena, ' '),  // tu był błąd, tj.
      dystans: distanceTmp
    }

    result.push(convertedObject);

  } // ~for

  return result;

}

export default foundCarAdvsConverter;