import React from 'react'
import { Message as SemanticMessage, Icon, Grid } from 'semantic-ui-react';

const Message:React.FC<any> = ({location: {state={}}}) => {  /* nested arg */

  // console.log(state.type);
  
  const type1= {
    [state.type]: true
  } 

  const iconCode = () => {
    switch (state.type) {
      case 'success': 
        return 'check circle';
      case 'error':
        return 'exclamation circle';
      case 'info':
        return 'info circle';
      default:
        return 'circle';
    }
  }

  // switch do ikon, albo dać redirect //

  return (  /* grid to normalne rozwiązanie, tak jak przy tym formularzu logowania */
    <Grid textAlign='center' verticalAlign="middle" style={{margin: '0px', height: '100%'}}>

      <Grid.Column mobile={14} tablet={9} computer={7}> 
      {state.message === undefined ? 'nieprawidłowe wywołanie podstrony bez wymaganych danych' : (
        <SemanticMessage icon style={{borderLeftStyle: 'solid', borderLeftWidth: '7px'}} {...type1}>
          <Icon name={iconCode()}/><span style={{fontSize: '16px'}}>{state.message}</span>
          <SemanticMessage.Content>
        </SemanticMessage.Content>
      </SemanticMessage>
      )}
      </Grid.Column>
    </Grid>




)

}

export default Message

/* 
    <SemanticMessage

    header='Wiadomość została wysłana'

    {...type1}

  /> */
