/* SCENARIUSZE, funkcjonalność:
  + zły login / hasło 
  + dobry email & hasło ; nie aktywne
  + dobry login & hasło ; nie aktywne
  + dobry email & hasło ; aktywne (console.log - token)
  + dobry login & hasło ; aktywne (console.log - token)
  + brak połączenia z internetem
*/

import { useUser } from '../../common/contexts/UserProvider';
import { FormikProvider, useFormik} from "formik"; // bez form nie łapie submit
import {
  Button,
  Form,
  Grid,
  Message,
  Segment
} from "semantic-ui-react";

import jwt from 'jwt-decode';

import { Icon } from "semantic-ui-react";
import FormikControl from "../../common/form-controls/FormikControl";

import * as Yup from "yup";

import { useHistory } from 'react-router-dom';
import React, {useState} from 'react';

import FormikButton from "../../common/form-controls/FormikButton";

import { Users } from "../../../src/api/agent";

// wczytanie const Message:React.FC<any> = ({location: {state={}}}) => {  /* nested arg */

// console.log(state.type); 

const LoginForm:React.FC<any> = ({location: {state={}}}) => {  // muszą być 2x puste obiekty inaczej przy przekierowaniu z PublicRoute bez obiektu location wyrzuci błąd

  const [displayedBar, setDisplayedBar] = useState(1);

  const { setUser } = useUser();
  const history = useHistory(); 

  const remindClicked = () => {
    history.push('/users/remind');
  }

  const registerClicked = () => {
    history.push('/users/register');
  }

  const resendClicked = () => {
    history.push('/users/resend-activation-mail');
  }

  function handleFormSubmit(values: any) {  
    
    // konwersja na to lower
    const login_emailLowerTmp = values.login_email.toLowerCase();

    values.login_email = login_emailLowerTmp;

    // Users.login(values)
    // axios.post(process.env.REACT_APP_API_SERVER_URL + 'users/login', values)
    Users.login(values)
      .then(function (response: any) {
        // 200 ok - tzn. udało się - zapisać token-a
        
        const token = response.token; // już nie response.data.token bo jest mapowane response
        
        const { login } = jwt<any>(token); // tak samo jak w innych kodach od razu wczytanie claima login

        setUser(login); // 
        localStorage.setItem('token', token); // 

        // === PRZEKIEROWANIE PO ZALOGOWANIU

        // console.log(state.referrer.pathname); wyrzuca błąd przez /msg
        if (state && state.referrer) {
          // console.log('warunek state - tzn. istnieje');
          // console.log(state.referrer);

          history.push(state.referrer);
        } else { // brak state referrer
          //console.log('warunek else - brak referer');
          history.push({
            pathname: '/msg',
            state: {
              type: 'success',
              message: 'Nastąpiło poprawne zalogowanie. Udostępniono dodawanie nowych ogłoszeń oraz zarządzanie wcześniej dodanymi.'  // error.response.data.message
            }
          });
        }

      }) // interceptor przechwytywał obiekt error, dlatego był undefined, 401 gdy błędne dane
      .catch(function (error) {
        formik.setSubmitting(false); // KONIECZNE, odblokowanie przycisku do następnej próby

        if (error.response === undefined) // brak połączenia z serwerem, musi być na samej górze
        {
          history.push({
            pathname: '/msg',
            state: {
              type: 'error',
              message: 'Wystąpił błąd połączenia z serwerem'
            }
          });

          return;
        }       

        else if (error.response.status === 401) // unauthorized , 403 dla nie aktywowane
        {         
          setDisplayedBar(1); // wyświetlenie przycisku "przypomnij"
          formik.setStatus(error.response.data.message);
        } else if (error.response.status === 403) {
          setDisplayedBar(2); // wyświetlenie przycisku "ponownie wyślij maila aktywacyjnego"
          formik.setStatus(error.response.data.message);
        }
      });
  }

  // ~~ axios request

  const formik = useFormik({
    // 3 args
    initialValues: {
      login_email: '',
      password: '',
      form_error: false    /* komunikat zbiorczy */
    },
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
    validationSchema: Yup.object({
      login_email: Yup.string().required(''),
      password: Yup.string().required('')
    }),
  });

  return (
    <Grid textAlign='center' verticalAlign='middle' style={{margin: '0px', height: '100%'}}> 

      <Grid.Column mobile={14} tablet={11} computer={8} style={{maxWidth: 670}}> {/* bez max width formularz się bardzo rozjeżdża np. przy 2100 */}
        <Segment clearing>

          <FormikProvider value={formik}>

            <div>
              <Message attached header={state.message !== undefined ? state.message : 'Formularz logowania'} />
              <Form
                onSubmit={formik.handleSubmit}
                className="attached fluid segment"
                onChange={()=>{formik.setStatus('');}} /* wyczyszczenie komunikatu */
              >                
                <FormikControl 
                  control='input'
                  name='login_email'
                  placeholder='login lub e-mail'
                  icon="user"
                  iconPosition="left"
                /><br/>
                <FormikControl 
                  control='input'
                  name='password'
                  placeholder='hasło'
                  icon="key"
                  iconPosition="left"
                  type="password"
                /><br/>
                <FormikButton
                  text="Zaloguj"
                  icon="sign in"
                  name="submit1"
                  type="submit"
                  positive={true}
                  fluid={true}
                  disabled={!(formik.isValid && formik.dirty) || !!(formik.status) || formik.isSubmitting}
                />
              </Form>
              {formik.status && (
                <Message
                  attached="bottom"
                  error
                  style={{ textAlign: "centered" }}
                >
                  <Icon name="exclamation" />
                  { formik.status }
                </Message>
              )}
            </div>
          </FormikProvider>
        </Segment>
        {(displayedBar === 1) ? (
        <Message style={{marginTop: '2em', padding: '0.1em', backgroundColor: '#f7f7f7'}}>  
            {/* szerokość przycisków zostaje wymuszona, wtedy równo się rozkladają odległości */}
            <Grid textAlign='center'>
              <Grid.Column mobile={16} tablet={8} computer={8}>
                <Button size='mini' basic icon='reply all' labelPosition='left' content='przypomnij' style={{width: 200}} onClick={remindClicked}/>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={8} computer={8}>
                <Button size='mini' basic icon='user add' labelPosition='left' content='rejestruj' style={{width: 200}} onClick={registerClicked}/>
              </Grid.Column>
            </Grid>          
        </Message>
        ) : (
          <Message style={{marginTop: '2em', padding: '0.7em', backgroundColor: '#f7f7f7', textAlign: 'center'}}>  
            Utracona wiadomość aktywacyjna ? &nbsp;&nbsp;&nbsp; <Button size='mini' basic icon='reply all' labelPosition='left' content='wyślij ponownie' onClick={resendClicked}/>
          </Message>          
        )}
      </Grid.Column>
    </Grid>
  );
};

export default LoginForm;
