/* SCENARIUSZE, testowana funkcjonalność
  + brak loginu w bazie /404/ - na dole
  + brak e-maila w bazie /404/
  + email znaleziony w bazie : wysłanie maila z przekierwoniem /msg
  + login znaleziony w bazie : wysłanie maila z przekierwoniem /msg
  + problem z mailerem / zły kod api / : przekierowanie do /msg
  - brak połączenia z serwerem

  =>? wszystkie pozostałe błędy - przekierowanie /msg - kod

  SCENARIUSZE WALIDACJI:
  - puste pole

  DO ROZBUDOWY:
  - spinner dla isSubmitting

  notatki: 424 - mailer, 204 - nie znaleziono
*/

import { FormikProvider, useFormik } from "formik"; // bez form nie łapie submit
import {
  Form,
  Grid,
  Message,
  Segment
} from "semantic-ui-react";

import { Icon } from "semantic-ui-react";
import FormikControl from "../../common/form-controls/FormikControl";
import FormikButton from "../../common/form-controls/FormikButton";

import * as Yup from "yup";
import React, { Fragment } from "react";
import { Users } from "../../api/agent";

// import convertError from "../../common/form-controls/ConvertError"


const SendResetPasswordMailForm = ({history}) => {
  
  // axios request

  function handleFormSubmit(values: any) {

    Users.sendResetPasswordMail(values)
      .then(function (response) {

        // przekierowanie do /msg : success
        // console.log(response);

        history.push({
          pathname: '/msg',
          state: {
            type: 'success',
            message: response
          }
        });  

      })
      .catch(function (error) {

        formik.setSubmitting(false); // KONIECZNE, odblokowanie przycisku do następnej próby

        // console.log(error.response.data.message);

        if (error.response === undefined) // brak połączenia z serwerem, musi być na samej górze
        {
          history.push({
            pathname: '/msg',
            state: {
              type: 'error',
              message: 'Wystąpił błąd połączenia z serwerem.'
            }
          });
        }

        if (error.response.status === 404) // nie znaleziono takiego usera w bazie
        {
          formik.setStatus(error.response.data.message);
        }

        if (error.response.status === 424) {  // nie wysłano e-maila
          history.push({
            pathname: '/msg',
            state: {
              type: 'error',
              message: error.response.data.message
            }
          });
        }

        /* wszystkie pozostałe błędy - przekierowanie msg */


      });
  }

  // ~~ axios request


  const formik = useFormik({
    // 3 args
    initialValues: {
      login_email: "",
      form_error: false    /* komunikat zbiorczy */
    },
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
    validationSchema: Yup.object({
      login_email: Yup.string().required('')
    }),
  });

  return (
    <Grid textAlign='center' verticalAlign="middle" style={{margin: '0px', height: '100%'}}>
      <Grid.Column mobile={14} tablet={12} computer={8} style={{maxWidth: 670}}>
        <Segment clearing style={{}}>
          <FormikProvider value={formik}>
            <Fragment>
              <Message attached header="Formularz resetowania hasła" />                        
              <Form
                onSubmit={formik.handleSubmit}
                className="attached fluid segment"
                onChange={()=>{formik.setStatus('');}} /* wyczyszczenie komunikatu */
              >
                <FormikControl 
                  control='input'
                  name='login_email'
                  placeholder='login lub e-mail'
                  icon="user"
                  iconPosition="left"
                /><br/>
                <FormikButton
                  text="Wyślij wiadomość"
                  icon="mail"
                  name="submit1"
                  type="submit"
                  positive={true}
                  fluid={true}
                  disabled={!(formik.isValid && formik.dirty) || !!(formik.status) || formik.isSubmitting}
                />
              </Form>
              {formik.status && (
                <Message
                  attached="bottom"
                  error
                  style={{ textAlign: "centered" }}
                >
                  <Icon name="exclamation" />
                  { formik.status }
                </Message>
              )}
            </Fragment>
          </FormikProvider>
        </Segment>
        

      </Grid.Column>
    </Grid>
  );
};

export default SendResetPasswordMailForm;
