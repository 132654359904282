/* PONIEWAŻ CROPPER JEST WARUNKOWO CHOWANY TO POLE FILE INPUT JEST 
  TUTAJ LUB W ZEWNĘTRZNYM KOMPONENCIE

  onChange ze sandBoxa zostało usunięte

  uploadPhoto musi być raczej w górnym komponencie, bo nie wiadomo jaki url, jeśli ma to być
  komponent wielokrotnego użycia
*/

import React, { Fragment, useState, useEffect } from 'react';
import Dropzone from './Dropzone';
import { Grid, Button, Card, Icon } from 'semantic-ui-react';


/* zmiana onchange pola input file powoduje ustawienie zmiennej stanu image, ktora jest
konfiguracją src dla obiektu Cropper */

import './PhotoUploader.css' /* bez tych styli preview nie działa prawidłowo, tzn. obraz się przemieszcza i wychodzi poza obramowanie */

import { Advs } from '../../api/agent';
import CropperComponent from './CropperComponent';

/*
const defaultSrc =
  "https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg";
*/

  interface IProps {
    // loading: boolean;
    // uploadPhoto: (file: Blob) => void;  // czyli cała funkcja jest przekazywana
    setSmallPhotosOut: (urls: any[]) => void; 
    setBigPhotosOut: (urls: any[]) => void;
    limit: number;
  }

// niech upload photo będzie jednak tutaj, a nie w komponencie wyżej i tu ta funkcja przez props:


const PhotoUploader: React.FC<IProps> = ({setSmallPhotosOut, setBigPhotosOut, limit}) => { // ,loading, uploadPhoto upload photo musi być wyżej
  
  const [uploadedSmallPhotos, setUploadedSmallPhotos] = useState<any[]>([]);
  const [uploadedBigPhotos, setUploadedBigPhotos] = useState<any[]>([]);

  const [photoUploadPending, setPhotoUploadPending] = useState<boolean>(false);

  const uploadPhoto = async (file: Blob) => {
    
    setPhotoUploadPending(true);

    try {
      // console.log(file);
      const photo = await Advs.uploadPhoto(file); // opisane w 
      // console.log(photo.url); OK
      // console.log(photo.eager[0].url); MINIATURKA
  
      const smallPhoto = photo.eager[0].url;
      const bigPhoto = photo.url;

      // wymuszenie https, inaczej nie załaduje obrazka na stronie z https
      // generalnie powinno być z warunkiem, gdyby np. przyszło jednak jako https nie dopisywać już s
      
      // txt4.charAt(4) musi być s

      let smallPhotoHttps; 
      let bigPhotoHttps;

      if ((smallPhoto.charAt(4) !== 's')) {
        smallPhotoHttps =  smallPhoto.slice(0, 4) + "s" + smallPhoto.slice(4);
      } else {
        smallPhotoHttps = smallPhoto;
      }
       
      if ((bigPhoto.charAt(4) !== 's')) {
        bigPhotoHttps = bigPhoto.slice(0, 4) + "s" + bigPhoto.slice(4);
      } else {
        bigPhotoHttps = bigPhoto;
      }

      const tmpSmallPhotosArray = uploadedSmallPhotos;
      const tmpBigPhotosArray = uploadedBigPhotos;
      
      // console.log(miniPhoto);

      // dodanie do tablic pomocniczych //
      tmpSmallPhotosArray.push(smallPhotoHttps);
      tmpBigPhotosArray.push(bigPhotoHttps);

      setPhotoUploadPending(false);
      
      setSelectedImgFiles([]); // ukrycie croppera

      // dopisanie urli do tablic
      setUploadedSmallPhotos(tmpSmallPhotosArray);
      setUploadedBigPhotos(tmpBigPhotosArray);

      // na zewnątrz
      setSmallPhotosOut(tmpSmallPhotosArray);
      setBigPhotosOut(tmpBigPhotosArray);
     
    } catch (error: any) {

      setPhotoUploadPending(false);
      console.log(error.response);

    }
  }

  // TO JEST Z POLA INPUT FILE, PRZEKAZYWANE DO CROPPERA
  // const [files, setFiles] = useState<any[]>([]);  // stare Files
  
  // WYNIKI Z CROPPERA
  const [croppedImage, setCroppedImage] = useState<Blob | null>(null);   // stare Image, setImage

  // test z onchange z kodem ze sandboxa, image jest ładowane jako src
  const [selectedImgFiles, setSelectedImgFiles] = useState<any>([]); // musi być any żeby działało Dropzone, oraz [] żeby działały warunki
  // setImage ustawia plik src croppera
  
  useEffect(() => {
    return () => {
     // selectedImgFiles.forEach(file => URL.revokeObjectURL(file.preview)); WYWOLUJE BLAD
    };
  });

  const handleDeletePhoto = (index) => {
    // console.log(index);

    const filteredSmall = uploadedSmallPhotos.filter((item, itemId) => itemId !== index );
   
    const filteredBig = uploadedBigPhotos.filter((item, itemId) => itemId !== index );

    setUploadedSmallPhotos(filteredSmall);
    setUploadedBigPhotos(filteredBig);

    // na zewnątrz:
    setSmallPhotosOut(filteredSmall);
    setBigPhotosOut(filteredBig);

  }

  const handleSetMainPhoto = (index) => {
    // console.log(index);

    const movedSmallPhoto = uploadedSmallPhotos[index];
    const movedBigPhoto = uploadedBigPhotos[index];
    // usunięcie elementu
    var filteredSmall = uploadedSmallPhotos.filter((item, itemId) => itemId !== index );
    var filteredBig = uploadedBigPhotos.filter((item, itemId) => itemId !== index );

    filteredSmall.unshift(movedSmallPhoto);
    filteredBig.unshift(movedBigPhoto);

    setUploadedSmallPhotos(filteredSmall);
    setUploadedBigPhotos(filteredBig);

    // na zewnątrz:
    setSmallPhotosOut(filteredSmall);
    setBigPhotosOut(filteredBig);
  }

  return (
    <div>
      <Grid>
        {uploadedSmallPhotos.length < limit && (
          <Grid.Row centered>{/* form control <PhotoWidgetDropzone setFiles={setFiles} />*/}
            {/* PRZYCISK UPLOAD */}            
            <Dropzone setFiles={setSelectedImgFiles} /> {/* ta sama funkcja co input file, musi być type any przy  */}
          </Grid.Row>
        )}
        {selectedImgFiles.length > 0 ? (
          
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={10} style={{paddingBottom: 0}}>
              
              { /* element cropper */ }
              <div style={{ width: "100%" }}>              
                {/* ustawienie danych wejściowych && pobierania wyjściowych przez props */}
                
                <Fragment>
                  <br/>
                  <div className="cropper-caption">PRZYCIĘCIE ZDJĘCIA</div>                 
                  <CropperComponent
                    setCroppedImage = {setCroppedImage}  //, OK WYJŚCIE Z CROPPERA
                    cropperSrcImage = {selectedImgFiles[0].imageUrlObject}   // files[0].preview WE to już jest z upload-a                    
                  />
                </Fragment>

              </div>

              {/* ~ element cropper */}
            </Grid.Column>

            <Grid.Column width="1" style={{padding: 0}}></Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={5} style={{padding: 0}}>    
              {/* element preview, musi mieć style dołączone, musi być też ten box inaczej przyciski się rozjeżdżają, są dłuższe pod obrazkiem */}
                
              <div className="preview-img-box" style={{border: '1px solid #e9e8e8', borderRadius: 5, marginBottom: 28}}>
              <div className="cropper-caption">PODGLĄD ZDJĘCIA</div>
              <div
                className="img-preview"
                style={{ width: "100%", height: "400px", textAlign: 'center'}}
              />
              <Button.Group size='mini' widths={2} style={{paddingTop: '5px', width: '100%'}}>
                <Button
                  positive
                  icon='check'
                  onClick={() => uploadPhoto(croppedImage!)}
                  loading={photoUploadPending}
                  type='button'
                />
                <Button
                  icon='close'
                  onClick={() => setSelectedImgFiles([])}
                  type='button'
                  // disabled={loading}
                />
              </Button.Group>
              </div> 

              {/* box, ~element preview */}
            </Grid.Column>

          </Grid.Row> // cały wiersz do ukrycia
        ) : (           // else WYŚWIETLANIE ZDJĘĆ NA DOLE
           <Grid.Row>
            <Grid.Column width="16" style={{paddingTop: '35px'}}>
            <Icon name='image' size="large" />
              dodanych zdjęć: <b>{ uploadedSmallPhotos.length} / {limit}</b> 
              {uploadedSmallPhotos.length > 0 && (
                <Fragment>
                  <div style={{display: 'inline'}}><Icon name='angle double up' size='small' style={{paddingLeft: 30}}/> &nbsp;oznacza zdjęcie główne</div>
                </Fragment>
              )}
              <br/><br/><br/>
              {/* iteracja */}
              <Card.Group itemsPerRow={3} style={{paddingBottom: 22}}>
                {uploadedSmallPhotos.length > 0 &&
                  uploadedSmallPhotos.map((smallPhoto: string, index) => (
                    <Card key={index} style={{width: 220, margin: '0 auto', marginBottom: 20}}>
                      <img src={smallPhoto} alt=""/>

                      <Button.Group fluid widths={2}>
                        <Button
                          name='approve' // set as main
                          basic 
                          positive
                          icon={index === 0 ? 'angle double up' : 'angle up'}
                          disabled={index === 0 ? true : false}
                          onClick={()=>{handleSetMainPhoto(index)}}
                          type='button'
                        />
                        <Button
                          name={index}
                          basic
                          negative
                          icon='trash'
                          onClick={()=>{handleDeletePhoto(index)}}
                          type='button'
                        />
                      </Button.Group>
                    </Card>
                  ))}
              </Card.Group>
            </Grid.Column>            
          </Grid.Row>
        )}   {/* ~WYŚWIETLANIE ZDJĘĆ NA DOLE */}
      </Grid>

      {/* =========POLE SANDBOX: <input type="file" onChange={onChange} />================================ */}
                
      <br style={{ clear: "both" }} />
         
    </div>
  );
};

export default PhotoUploader;
