/*
 rotacja: nie ma na nią znaczenia wielkość czcionki,
 a jedynie rozmiar ikony, 1 px za dużo / mało - jest już rotacja, najłatwiej to podejrzeć
 na circle lub circle notch, można nawet ikonę ująć w spana z ramką solid
 16 to dobra wartość
*/

import React from 'react'
import { useFormikContext } from "formik"
import { Form, Icon } from 'semantic-ui-react'

const FormikButton = ({text, icon, ...rest}) => {  // style, jako default,       type="submit" fluid, positive
  const formik = useFormikContext(); 

  return (
    <Form.Button   /* musiałby by brać icon name, name */
      disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting }
      {...rest}
    >
      <Icon style={{height: '16px'}} name={formik.isSubmitting ? 'spinner' : icon} loading={formik.isSubmitting} />&nbsp;&nbsp;{text}
    </Form.Button>
  )
}

export default FormikButton