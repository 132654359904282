const allowOnlyNumber = (e, allowDecimal: boolean, allowPlus: boolean = false) => {    // , - 188  . - 190
  var key = e.charCode || e.keyCode || 0;
   
   // console.log(e.keyCode)
   // console.log(allowDecimal);

  if ((allowPlus && key === 16) || (allowPlus && key === 61))
    return;

  if (allowDecimal && key === 190)
    return;

  if (!(
    key === 8 || 
    key === 9 ||
    key === 13 ||
    key === 46 ||
    key === 110 ||
    (key >= 35 && key <= 40) ||
    (key >= 48 && key <= 57) ||
    (key >= 96 && key <= 105))) {
      e.preventDefault();
    }
}

export default allowOnlyNumber;