/* WNIOSKI: ewentualnie można przekazać obiekt przez parametr formik -  tak też działa
a składnia przy odczycie props to [], obiekt formik jest dostępny poprzez użyciue useFormikContext w zagnieżdżonych komponentach, z kolei składnia z FormikProvider jest konieczna do ustawiania błędów manualnych */

/* import { Field, ErrorMessage, useFormikContext } from "formik" */

/* TEORIA ONBLUR
  z dokumentacji: Useful for when you need to track whether an input has been touched or not

  onBlur jest przechwytywane żeby dopisać do niego kod, tj. jest to potrzebne przy obsłudze wartości domyślnych formularza search

  jeśli nie ma włączonego onBlur to np. przy rejestracji, nie wywoła się regex na loginie przy onBlur
*/

/* USTAWIANIE TEKSTU POLA Z POZIOMU KODU:
 - PRZEZ POLE
*/

import React from 'react';
import { useFormikContext } from "formik"
import { Form, Label } from 'semantic-ui-react'; // tylko w przypadku elementu Form.Input dostępne są te etykiety błędów
import convertError from './ConvertError';

function Input(props) {
  const { name, label, isInt=false, isFloat=false, appendedLabel, attachedHandleBlur, ...rest } = props
  const formik = useFormikContext();

  const handleBlur = (event) => {        

    formik.handleBlur(event); // bez tego nie ma np. wywołanie regex-a

    // wywołanie dołączonej funkcji
    if (attachedHandleBlur) // jako parametr opcjonalny może być undefined
      attachedHandleBlur();
  }

  const handleChange = (txtVal, fieldName) => {
    
    try {
    if (isInt)
    //
      if (txtVal.length !== 0) { // bez warunku na pustym spolu backspace wyrzuca blad
        formik.setFieldValue(fieldName, parseInt(txtVal));
      } else {
        formik.setFieldValue(fieldName, txtVal);
      }
    //
    else if (isFloat) {
      
      // FLOAT
      // przepuszczenie kropki, inaczej nie byłaby wyświetlona
      let lastCharacter = '';

      if (txtVal.length > 0) { // bez warunku gdy . jest pierwsza wywola blad
        lastCharacter = txtVal.at(-1);  
      }
    
      if (lastCharacter === '.' || lastCharacter === '0') { // rezygnacja z walidacji, też przy pustym polu
        formik.setFieldValue(fieldName, txtVal);
        // console.log('warunek kropka');
        return;
      }

      // puste pole, np. po backspace, inaczej błąd przy konwersji
      if (txtVal === '') { // rezygnacja z walidacji, też przy pustym polu
        formik.setFieldValue(fieldName, txtVal);
        // console.log('warunek puste pole');
        return;
      }

      formik.setFieldValue(fieldName, parseFloat(txtVal));
      // ~ FLOAT
    } else 
      formik.setFieldValue(fieldName, txtVal);
    } catch {
      formik.setFieldValue(fieldName, '');  // null ?
    }
  }

  if (appendedLabel) 
    return (
        <table style={{margin: 0}}>
          <tbody>
            <tr>
              <td width="100%">
                <Form.Input  name={name} {...rest} 
                      error={convertError(formik.touched[name], formik.errors[name]) }
                      onChange={(e) => {handleChange(e.target.value, name)}}  // onChange={formik.handleChange}  
                      onBlur={(e) => {handleBlur(e)}} // onBlur={formik.handleBlur}, 2x w kodzie
                      value={formik.values[name]} 
                      fluid={true}
                />
            </td>
              <td>
                <Label style={{marginLeft: 7, padding:'10px', width: '50px', textAlign:'center'}}>{appendedLabel}</Label>             
              </td>
            </tr>
          </tbody>
        </table>    
  ) 
  else return (
    <Form.Input  name={name} {...rest} 
      error={convertError(formik.touched[name], formik.errors[name]) }
      onChange={(e) => {handleChange(e.target.value, name)}}  
      onBlur={(e) => {handleBlur(e)}} // onBlur={formik.handleBlur}, 2x w kodzie
      value={formik.values[name]} 
      fluid={true}
/>
  )
}
export default Input

/* oryginalny:
      <Form.Input  name={name} {...rest} 
                   error={convertError(formik.touched[name], formik.errors[name]) }
                   onChange={formik.handleChange}  
                   onBlur={formik.handleBlur}
                   value={formik.values[name]} 
                   fluid={true}    
      />
*/


/*  
  GDY FIELD BYŁO Z BIBLIOTEKI FORMIK:
<div>
      <label htmlFor={name}> {label}</label>
      <Field name={name} {...rest} />      
      <ErrorMessage name={name} />
    </div>  
*/

/* 
                   labelPosition='right'
                   label={{ basic: true, content: 'kg' }}

        {innerLabel && (
          <Fragment><input/>
            <Label><span style={{position: 'relative', top: '8px'}}>{innerLabel}</span></Label>
          </Fragment>
        )} 

                   */