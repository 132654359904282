import React, { Fragment, useState, useEffect} from 'react';
import { Button, Container, Grid, Header, Icon } from 'semantic-ui-react';
import { Advs } from '../../api/agent';
import convertOwnAdvsFromDb from '../../models/convert/ownAdvsConverter';
import IOwnAdvForDisplay from '../../models/ownAdvModelForDisplay';

const OwnAdvsList = ({history}) => {

  const [ownAdvs, setOwnAdvs] = useState<IOwnAdvForDisplay[] | undefined>(undefined); // wczytywana w useEffect z axios request
  // brakuje rok produkcji, pojemnosc silnika
  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);

  const fetchOwnAdvs = async () => {
    const data = await Advs.getOwnAdvs();
    
    // console.log(data);

    const advsConverted = convertOwnAdvsFromDb(data); // ?? czy w ogóle jest to potrzebne ??
    setIsDataLoading(false);
    setOwnAdvs(advsConverted);
  }

  const handlePreview = (arg: string) => {
    // console.log(arg);

    history.push({
      pathname: '/advs/cars/view/' + arg
    });
  }

  const handleDelete = async (arg: string) => {

    const output = await window.confirm("Czy na pewno usunąć ogłoszenie ?");

    if (output === false)
      return;
    
    const data = await Advs.deleteAdv(arg).catch((error)=> { 
      console.log(error);
    
    // uwaga: testowanie obecności błędu konieczne jest z ... inaczej undefined
    let messageTmp;
    if (error && error.response && error.response.data && error.response.data.message)  // w przypadku innego błędu ten obiekt będzie undefined
      messageTmp = error.response.data.message;
    else
      messageTmp = "Wystąpił błąd aplikacji.";  

      // przekierowanie
      history.push({
        pathname: '/msg',
        state: {
          type: 'error',
          message: messageTmp
        }
      });
    })

    // console.log(data.deletedCount); // po usunięciu
    if (data.deletedCount === 1)   // ewent. spinner
    {

      var noDeletedAdv = ownAdvs?.filter( el => el._id !== arg ); 

      setOwnAdvs(noDeletedAdv); // aktualizacja danych
    }
  }

  useEffect(() => {  // component did mount       

     // make sure to catch any error MUSI BYĆ Z NAWIASAMI ()
     fetchOwnAdvs().catch((error)=> {          // kwestia undefined jeszcze ..., na 404 mógłby być interceptor
       // obsluga bledu: konsola + toast
       console.log(error.response);
     
       let messageTmp;
       if (error && error.response && error.response.data && error.response.data.message)  // w przypadku innego błędu ten obiekt będzie undefined
         messageTmp = error.response.data.message;
       else
         messageTmp = "Wystąpił błąd aplikacji.";  

       history.push({
         pathname: '/msg',
         state: {
           type: 'error',
           message: messageTmp
         }
       });
 
       // ~ obsluga bledu
     });
 
   }, []);
  
  return (
    <Container>
      <br/><br/>
      <Grid style={{fontSize: 18}} verticalAlign='middle'>

        {isDataLoading && (
          <div style={{width: '100%', textAlign: 'center'}}>
            <Icon loading name='circle notch' size='big' />
          </div>
        )}

        {ownAdvs?.length === 0 && isDataLoading === false &&
        <Header as='h3'>Brak aktualnych ogłoszeń</Header> }

        {ownAdvs?.map((ownAdv, i) => {
          return (
            <Grid.Row key={i} style={{margin: '7px', border: '1px solid #c4c4c4'}}>{/* wiersz */}
              <Grid.Column mobile={16} tablet={4} computer={5} style={{fontWeight: 'bold', paddingBottom: 10, paddingTop: 10}}>
                {i+1}. {ownAdv.producent} {ownAdv.model}
              </Grid.Column>
    
              <Grid.Column mobile={8} tablet={3} computer={3}>
                {ownAdv.cena}
              </Grid.Column>
    
              <Grid.Column mobile={8} tablet={4} computer={4}>
                {ownAdv.lokalizacja}
              </Grid.Column>
    
              {/* przyciski */}
              <Grid.Column mobile={16} tablet={5} computer={4} style={{textAlign: 'right'}}>               
                <div style={{ padding: 10 }}>
                  <Button animated='vertical' style={{display: 'inline', float: 'left'}} onClick={()=>handlePreview(ownAdv._id)}>
                    <Button.Content hidden>Podgląd</Button.Content>
                    <Button.Content visible>
                      <Icon name='eye' />
                    </Button.Content>
                  </Button>
                  <Button animated='vertical' color='red' style={{display: 'inline', marginLeft: '25px'}} onClick={()=>handleDelete(ownAdv._id)}>
                    <Button.Content hidden>Usuń</Button.Content>
                    <Button.Content visible>
                      <Icon name='trash alternate' />
                    </Button.Content>
                  </Button>
                </div>          
              </Grid.Column>                    
            {/* ~wiersz */}          
          </Grid.Row>
          );
        })} 
        
        
       
      </Grid>
    </Container>
  )
}

export default OwnAdvsList