import React from "react";
import { ReactComponent as Sun } from "./Sun.svg";
import { ReactComponent as Moon } from "./Moon.svg";
import "./DarkMode.css";
import { useUpdateTheme, useTheme } from "../../../common/contexts/ThemeProvider2";



const DarkMode = () => {

    const updateTheme = useUpdateTheme(); // UWAGA, BEZ {}

    const currentTheme = useTheme(); // do wczytania pozycji suwaka po 'enter'
          
    /* add 1 attribute to body element: 
       local storage użyte bo bez niego po refresh strona zawsze startuje w light theme
    */
    const setDarkMode = () => {
        updateTheme('dark');   // z ThemeProvider2     
    }    
    
    const setLightMode = () => {
        updateTheme('light');
    }

    // const selectedTheme = localStorage.getItem("selectedTheme");

    // if (selectedTheme === "dark") {
    //     //setTheme('dark');
    //     setDarkMode(); // we call the function
    // } 

    /* TOGGLE THEME TRZEBA PRZENIEŚĆ W GÓRE */
    
    /* it gets the event object, można zmiany podejrzeć na tagu body w źródle */
    const toggleTheme = (e) => {
        if (e.target.checked) // wywolanie funkcji
            { setDarkMode(); } // setTheme('dark'); }
        else
            { setLightMode(); } //}
    }

    return (
        <div className='dark_mode' style={{position: 'relative', top: '0px'}}>
            <input
                className='dark_mode_input'
                type='checkbox'
                id='darkmode-toggle'
                onChange={toggleTheme}
                defaultChecked={currentTheme === 'dark'} /* po odświeżeniu strony ustawienie właściwego stanu */
            />
            <label className='dark_mode_label' htmlFor='darkmode-toggle'>
                <Sun />
                <Moon />
            </label>
        </div>
    );
};

export default DarkMode;
