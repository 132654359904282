/* należy postąpić zgodnie z DRY - importować gotowe modele których używa formularz */
/* to jest tablica obiektów, trzeba więc znaleźć obiekt którego value spełnia warunek arg */
/* musi być też ten warunek, gdyby argument był błędny */

/* 
  const vehicle_types = [
    { key: 1, value: 1, text: 'samochód osobowy' },
  ];
*/

/* 
  konwersję można by wykonać od razu po stronie serwera, ale lepiej go dodaktowo nie obciążać, tzn. 
  operację przeprowadzic po stronie użytkownika
*/

import car_brands from '../../common/data/select-options/car_brands';
import vehicle_states from '../../common/data/select-options/vehicle_states';
import { vehicle_colors } from '../../common/data/select-options/vehicle_colors';
import car_fuel_types from '../../common/data/select-options/car_fuel_types';
import car_gearbox_types from '../../common/data/select-options/car_gearbox_types';
import IAdvCarForDisplay from '../advCarModelForDisplay';
import IAdvCarForViewFromDb from '../advCarForViewFromDb';
import addIntegerSeparators from '../../common/helpers/AddIntegerSeparators';

/*
const convertRodzajPojazdu = (arg: Number): string => {
  const index = vehicle_types.findIndex((obj) => obj.value === arg);

  if (index === -1) // musi być też ten warunek, gdyby argument był błędny
    return '[!arg]';
    
  const object = vehicle_types[index];

  return object.text;
}
*/

const convertProducent = (arg: Number): string => {
  const index = car_brands.findIndex((obj) => obj.value === arg);

  if (index === -1) // musi być też ten warunek, gdyby argument był błędny
    return '[!arg]';
    
  const object = car_brands[index];

  return object.text;
}


const convertStan = (arg: Number) => {
  const index = vehicle_states.findIndex((obj) => obj.value === arg);

  if (index === -1) // musi być też ten warunek, gdyby argument był błędny
    return '[!arg]';
    
  const object = vehicle_states[index];

  return object.text;
}


const convertKolor = (arg: Number) => {
  const index = vehicle_colors.findIndex((obj) => obj.value === arg);

  if (index === -1) // musi być też ten warunek, gdyby argument był błędny
    return '[!arg]';
    
  const object = vehicle_colors[index];

  return object.text;  
}


const convertRodzajPaliwa = (arg: Number) => {
  const index = car_fuel_types.findIndex((obj) => obj.value === arg);

  if (index === -1) // musi być też ten warunek, gdyby argument był błędny
    return '[!arg]';
    
  const object = car_fuel_types[index];

  return object.text;
}


const convertRodzajSkrzyni = (arg: Number) => {
  const index = car_gearbox_types.findIndex((obj) => obj.value === arg);

  if (index === -1) // musi być też ten warunek, gdyby argument był błędny
    return '[!arg]';
    
  const object = car_gearbox_types[index];

  return object.text;
}

// ===================================================================
// ===================================================================

/* tak kod będzie czystszy, bez bajzlu, 
   a konwersja wykonana w przeglądarce, bez obciążania serwera */

const convertAdvCarFromDb = (arg: IAdvCarForViewFromDb) => {
  
  // =UŻYCIE KONWERTERÓW===================================================
  let tmpProducent = "";
  if (arg.producent_inny !== "")
    tmpProducent = arg.producent_inny;
  else 
    tmpProducent = convertProducent(arg.producent);

  let tmpKolor = "";
  if (arg.kolor_inny !== "")
    tmpKolor = arg.kolor_inny;
  else 
    tmpKolor = convertKolor(arg.kolor);

  let wyposazenie: string[] = [];

  if (arg.abs)
    wyposazenie.push('abs');

  if (arg.klima)
    wyposazenie.push('klimatyzacja');

  if (arg.immo)
    wyposazenie.push('immobilizer');

  if (arg.centralka)
    wyposazenie.push('centralny zamek');

  if (arg.gps)
    wyposazenie.push('nawigacja');

  if (arg.radio)
    wyposazenie.push('radio');

  if(arg.czujniki_parkowania)
    wyposazenie.push('czujniki parkowania');

  if(arg.el_szyby)
    wyposazenie.push('elektryczne szyby');

  if(arg.el_lusterka)
    wyposazenie.push('elektryczne lusterka');

  if(arg.poduszkiQty>0) { // nie wyświetlanie gdy ich nie ma
    const tmpPoduszki = 'poduszki powietrzne: ' + arg.poduszkiQty;
    wyposazenie.push(tmpPoduszki);
  }

    // =======================================================================
  
  //where x is already the integer part of the number

  const resultObject: IAdvCarForDisplay = {
    producent : tmpProducent, // +
    model: arg.model,
    stan : convertStan(arg.stan),
    cena : addIntegerSeparators(arg.cena) + ' zł',
    rok_produkcji : arg.rok_produkcji,
    przebieg : addIntegerSeparators(arg.przebieg) + ' km',
    kolor : tmpKolor, // +
    rodzaj_paliwa : convertRodzajPaliwa(arg.rodzaj_paliwa),
    pojemnosc_silnika : arg.pojemnosc_silnika.toFixed(1) + ' L',
    moc_silnika : arg.moc_silnika,
    rodzaj_skrzyni : convertRodzajSkrzyni(arg.rodzaj_skrzyni),
    opcje_wyposazenia: wyposazenie,
    opis : arg.opis,
    lokalizacja : arg.miejsce.city + ", " + arg.miejsce.zip,
    smallPhotos : arg.smallPhotos,
    bigPhotos : arg.bigPhotos,
    dataWygasania: arg.dataWygasania, /* z obliczenia created At lub nowego pola w bazie */
  };

  return resultObject;

}

/* lepiej zrobić kon
interface IAdvCarForDisplay {
  
}
*/


export default convertAdvCarFromDb;