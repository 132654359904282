/* stary układ: dystans: 10km */

const radius_distance_list = [
  { key: 1, value: 5000, text: "5 km" }, 
  { key: 2, value: 10000, text: "10 km" },
  { key: 3, value: 20000, text: "20 km" },
  { key: 4, value: 50000, text: "50 km" },
];

/*
const disabled_distance_list = [
  { key: 1, value: '', text: "---" }, 
];
*/

export default radius_distance_list;