import React from "react"
import { useFormikContext } from "formik"
import { ErrorMessage } from "formik"
import {Dropdown as SemanticDropDown} from 'semantic-ui-react'
import convertErrorBoolean from "./ConvertErrorBoolean";  // select nie toleruje string

// field odpada, bo jest z formika, a ma być z semantic ui //
//<option value="" disabled selected hidden>...</option>
function DropDown(props) {
  const { label, value, noResultsMessage = null, clearable=true, disabled=false, name, options, setFluid=true, placeholderText='', changeCallback=()=>{} } = props
  const formik = useFormikContext(); 

  /* uwaga: tutaj jeśli się nie da tego await to przy wywołaniu Callback nie działa
    poprawnie walidacja, tj. nie gasi błędu, np. przy kliknięciu na Główne, a później Alfa
    jeśli się wyłączy changeCallback to działa, tak samo jak jeśli się doda te await-y
  */
  const handleChange = async (name, value, changeCallback) => {

    await formik.setFieldTouched(name, true);
    await formik.setFieldValue(name, value);
    // formik.setFieldTouched (name, false, false);  // czyszczenie błędu
    // console.log(formik.values.producent) TUTAJ NIE DZIAŁA, NAWET Z AWAIT;
    changeCallback(value); // wywołanie dalszej funkcji
  }

  return (
    <div>
      <label htmlFor={name}>{label}</label>
      <SemanticDropDown
        search
        selection
        clearable={clearable}
        noResultsMessage={noResultsMessage}
        className="semanticSelect" // dodane w celu rozdzielenia styli od paska nawigacyjnego
        defaultValue={value}  // musi być
        disabled={disabled}
        options={options}
        fluid={setFluid}
        error={convertErrorBoolean(formik.touched[name], formik.errors[name]) }
        placeholder={placeholderText}        
        onChange={(e, data) => {handleChange(name, data.value, changeCallback)}}
        // onBlur={formik.handleBlur}
      >{/*  */}
        
      </SemanticDropDown>
      <ErrorMessage name={name} />
    </div>
  )
}

export default DropDown