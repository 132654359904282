/* przy view profile też jest get request, w Neils oraz Mern 

przy załączeniu interceptor header nie działa wysłanie zdjęć do cloudinary,

( dodatkowy opis na dole )

*/

import axios, { AxiosResponse } from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_SERVER_URL;

const cloudinaryUrl = 'https://api.cloudinary.com/v1_1/dtpjgv1zo/image/upload';

// ZAŁĄCZANIE TOKENA Z LOCAL STORAGE
axios.interceptors.request.use((config) => {
    const token = window.localStorage.getItem('token');
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
}, error => {
    return Promise.reject(error);
})


// bez throw później już nie występuje drugi raz
/* JAK TUTAJ ZŁAPIE ERROR, TO PÓŹNIEJ WYWOŁA CATCH, ALE OBIEKT ERROR JEST PUSTY -
    TJ. PROBLEM Z LOGOWANIE WYSTĘPUJE PRZY BŁĘDNYCH DANYCH

    !!! konieczne jest throw na samym dole, tak żeby błąd dalej propagować i żeby został on
    złapany w blokach catch // ( które i tak są wywoływane nawet bez instrukcji throw )
    ale wtedy już error jest undefined

    zwykłe 401 jest zwracane przy wrong credentials
*/

/* można w ogóle na razie to wyłączyć, później dodać do wykrycia no connection
axios.interceptors.response.use(undefined, error => {
    if (error.message === 'Network Error' && !error.response) {
        console.log('Network error - make sure API is running!')
    }
    //const {status, data, config} = error.response;
    //if (status === 404) {
    //    console.log('/notfound')
    // }    

    throw error; // konieczne do odczytu błędu w blokach catch, np. 401 - błędne dane logowania
    // bez tej insrukcji blok catch się wykonuje ale nie ma już obiektu error
});
*/

// MAPOWANIE OBIEKTU RESPONSE - TJ. przy odbiorze nie stosuje się już response.data.token tylko response.token
// ale tylko na 200, error nie jest mapowany
const responseBody = (response: AxiosResponse) => response.data;  // !!! reszta obiektu response jest pomijana

const sleep = (ms: number) => (response: AxiosResponse) => 
    new Promise<AxiosResponse>(resolve => setTimeout(() => resolve(response), ms));

    const instanceNoAuthInterceptor = axios.create();  // do cloudinary nie może być z Auth

let extra_delay = 0; // brak zwłoki na production

if (process.env.NODE_ENV === 'development')
    extra_delay = 1000; // zwykle 1000, ewent. 5000

const requests = {
    get: (url: string) => axios.get(url).then(sleep(extra_delay)).then(responseBody),
    post: (url: string, body: {}) => axios.post(url, body).then(sleep(extra_delay)).then(responseBody),
    put: (url: string, body: {}) => axios.put(url, body).then(sleep(extra_delay)).then(responseBody),
    del: (url: string) => axios.delete(url).then(sleep(extra_delay)).then(responseBody),
    postForm: (url: string, file: Blob) => {
        let formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', 'main_preset');
        return instanceNoAuthInterceptor.post(url, formData, {
            headers: {'Content-type': 'multipart/form-data'},
        }).then(responseBody)
    }
};

/*
const Activities = {
    list: (): Promise<IActivity[]> => requests.get('/activities'),
    details: (id: string) => requests.get(`/activities/${id}`),
    create: (activity: IActivity) => requests.post('/activities', activity),
    update: (activity: IActivity) => requests.put(`/activities/${activity.id}`, activity),
    delete: (id: string) => requests.del(`/activities/${id}`),
    attend: (id: string) => requests.post(`/activities/${id}/attend`, {}),
    unattend: (id: string) => requests.del(`/activities/${id}/attend`)
}

const User = {
    current: (): Promise<IUser> => requests.get('/user'),
    login: (user: IUserFormValues): Promise<IUser> => requests.post(`/user/login`, user),
    register: (user: IUserFormValues): Promise<IUser> => requests.post(`/user/register`, user),
}
Promise<IPhoto>
*/

/* jak nie ma obiektu post to dawać normalnie get */

export const Advs = {
    uploadPhoto: (photo: Blob): Promise<any> => requests.postForm(cloudinaryUrl, photo),
    getAdvCar: (id: string) => requests.get(`/advs/cars/view/${id}`),  // jak nie ma body to niech będzie get
    saveAdv: (adv: any) => requests.post('/advs/cars/new', adv), // 2 arg - request, body
    getOwnAdvs: () => requests.get('/advs/own-advs'),
    deleteAdv: (id: string) => requests.get(`/advs/delete/${id}`),
    searchCarAdvs: (query: any) => requests.post('/advs/cars/search', query),
    report: (id, token) => requests.get(`/advs/report/${id}/${token}`),
    getAdvCarContactData: (id, token) => requests.get(`/advs/contact-data/${id}/${token}`),
}

export const Users = {
    login: (formValues: any) => requests.post('/users/login', formValues),
    activate: (userId: any, hashCode: any): Promise<any> => requests.post(`/users/activate/${userId}/${hashCode}`, {}),
    register: (formValues: any): Promise<any> => requests.post('/users/register', formValues),
    resendActivationMail: (formValues: any): Promise<any> => requests.post('/users/resend-activation-mail', formValues), 
    resetPassword: (formValues: any): Promise<any> => requests.post('/users/reset-password', formValues),
    sendResetPasswordMail: (formValues: any): Promise<any> => requests.post('users/send-reset-password-mail', formValues),
    updatePassword: (formValues: any): Promise<any> => requests.post('users/update-password', formValues), // musi być z tokenem
    checkRpLink: (formValues: any): Promise<any> => requests.post('users/check-rp-link', formValues),
}

export const OsmPlaces = {
  search: (place: any) => requests.post('/geo/search', place)
}

// w ten sposób export robił NEIL, tj. wiele props

/*
const exportedObject = {
    Advs,
    Users
}

export default exportedObject;
*/

/* 
    w kursie Neila jest export default z wieloma obiektami jako props,
    co później jest importowane w całości jako agent
    wywoływane jest przez agent.___ np: users . _____

    druga opcja to eksport wielu funkcji i później import tylko tego co potrzebne

    generalnie składnia z użyciem pojedyńczego pliku agent powinna ułatwić wykorzystanie error interceptor-a czy 
    wprowadzenie stor-ów

    jak jest promise, to działa też ta składnia z .then, .catch
    
==> przepisanie na response.data:
       
*/