/* 
  https://react.semantic-ui.com/modules/search/
  1 - skopiowanie całego kodu do zupełnie nowego komponentu i tam testy statyczne 
  - dodanie : any
  - let source = [  // bez const, bo jest nadpisywane
*/

import _ from 'lodash'
//import faker from 'faker'
import React, { useRef, useState } from 'react'
import { GridColumn, Search, Grid, Header, Segment } from 'semantic-ui-react'

// const source = _.times(5, () => ({
//   title: faker.company.companyName(),
//   description: faker.company.catchPhrase(),
//   image: faker.internet.avatar(),
//   price: faker.finance.amount(0, 100, 2, '$'),
// }))

import { OsmPlaces } from '../../../api/agent';
import IOsmACReturnedData from './OsmAutoCompleteDataModel';

let source: any = []; // bez const, bo jest nadpisywane wcześniej tutaj dane

let outputObject:any = undefined; // to jest zmienna z wynikiem, do przekazania dalej do kodu

const debug_mode = false; // wyświetlanie komunikatów w konsoli, ważnych przy analizie działania

function provinceToString(argProvinceNum: number) {
 switch(argProvinceNum)
 {
  case 1:
    return "dolnośląskie";
  case 2:
    return "kujawsko-pomorskie";
  case 3:
    return "łódzkie";
  case 4:
    return "lubelskie";    
  case 5:
    return "lubuskie";
  case 6:
    return "małopolskie";
  case 7:
    return "mazowieckie";;
  case 8:
    return "opolskie";
  case 9:
    return "podkarpackie";
  case 10:
    return "podlaskie";
  case 11:
    return "pomorskie";
  case 12:
    return "śląskie";
  case 13:
    return "świętokrzyskie";
  case 14:
    return "warmińsko-mazurskie";
  case 15:
    return "wielkopolskie";
  case 16:
    return "zachodniopomorskie";
 }

 return ""; // default
}

async function loadAutocompleteData(argStrPlace: String)
{

  //source = []; // ?? ważne, czyszczenie poprzednich wyników, istotne przy kolejnym wyszukiwaniu

  let osmPlacesFromDb: any = [];

  if (argStrPlace.length > 0)
  {
    
    osmPlacesFromDb = await OsmPlaces.search({place: argStrPlace});

  } else
  {
    if (debug_mode)
      console.log('zerowa długość argumentu dla requesta API, nie jest on wysyłany');

    // osmPlacesFromDb = []; nie jest to konieczne
  }

  if (debug_mode)
  {
    console.log('osmPlacesFromDb pobrane axiose-em:');
    console.log(osmPlacesFromDb);
  }

  // === PRZEPISANIE NA OBIEKT DANYCH AUTOCOMPLETE
  let results: any = [];
  let item: any = "";

  for (let i = 0; i < osmPlacesFromDb.length; i++) {
    // console.log(osmPlacesFromDb[i].Name);
    // console.log(osmPlacesFromDb[i].County);

    if (debug_mode)
    {
      console.log('uruchomienie iteracji przepisywania tablicy');
    }

    let strDescription = "";

    if (osmPlacesFromDb[i].Municipality == osmPlacesFromDb[i].Name) // np. Warszawa gmina Warszawa - nie dopisywać słowa gmina
      strDescription = provinceToString(osmPlacesFromDb[i].Province);
    else // różne - dopisać
      strDescription = "gm. " + osmPlacesFromDb[i].Municipality + ", " + provinceToString(osmPlacesFromDb[i].Province);

    item = {
      "key": i, // musi być dodatkowo key bo jak 2x item sulejów to są błędy w konsoli
      "title": osmPlacesFromDb[i].Name,
      "description": strDescription,

      // NADMIAROWE POLA:
      "lat": osmPlacesFromDb[i].Latitude,
      "lon": osmPlacesFromDb[i].Longitude,
      // dodatkowe na przyszłość: 
      "municipality": osmPlacesFromDb[i].Municipality,
      "zip": osmPlacesFromDb[i].StrZipCode,
      "county": osmPlacesFromDb[i].County,
      "province": provinceToString(osmPlacesFromDb[i].Province)      
    }

    results.push(item);
    //console.log(item);

  } // ~ foreach

   if (debug_mode)
   {
     console.log('results jako nadpisana tablica danych pobranych z API:');
     console.log(results);
   }


  source = results; // ?? zupełnie nie widzi ?? // jakoś źle to jest nadpisywane

/*
  source = [
    {
      "title": "Warszawa",
      "description": "gm. Sulejów, mazowieckie",
      "latitude": 2
    }, 
    {
      "title": "Poniatów",
      "description": "gm. Sulejów, mazowieckie",
      "latitude": 2
    }, 
    {
      "title": "Kraków",
      "description": "gm. Sulejów, mazowieckie",
      "latitude": 2
    }
  ];
*/
  return;
};

const initialState = {
  loading: false,
  results: [],
  value: '',
  displaySpinner: false // dopisane
}

function exampleReducer(state, action) {
  switch (action.type) {
    case 'CLEAN_QUERY': // to co po kliknięciu
      return initialState;
    case 'START_SEARCH': // zachodzi przy każdym zdarzeniu nacisku klawisza
      state = ""; // bardzo ważna linia, usuwa stare rekordy po poprzednich wyszukiwaniach, np. Bydgoszcz
      
      // console.log('czyszczenie outputObject - linia 163'); tu nie czyścić bo jest brak dostępu do metody setOutputData

      //console.log("aktualny outputObject:");
      //console.log(outputObject);

      return { ...state, loading: true, value: action.query } // oryginalny kod
      // return { ...state, loading: false, value: action.query }  // usunięcie loading sprawia że pojawia się "brak wyników" od razu
    case 'FINISH_SEARCH':
      return { ...state, loading: false, results: action.results }
    case 'UPDATE_SELECTION':
      // outputObject = action.selection; // sama nazwa miejscowości która się pojawia w polu tekstowym po kliknięciu item

      //  console.log("wybrano outputObject:");
      //  console.log(action.selection);
     
      return { ...state, value: action.selection }

    case 'START_SPINNER': // dopisane
      return { ...state, displaySpinner: true }  // stary stan + nadpisanie 1 prop

    case 'STOP_SPINNER': // dopisane
      return { ...state, displaySpinner: false }

    default:
      throw new Error()
  }
}

let inputText = ""; // przechowuje tekst z pola autocomplete, jest używany przy wysyłanym request


function OsmAutoComplete({onOsmChange, onOsmBlur=()=>{}}) // onOsmBlur - parametr opcjonalny, jednak rezygnacja z setOutputData, potrzebne jest zdarzenie onChange przy każdym wcisniętym klawiszu gdyż ktoś może skasować pole i wcisnąć search, a dane będą w pamięci, osmOnBlur jest potrzebne do walidacji, tam ustawia się touched dla formik-a
 {  // IOsmACReturnedData

  const refAC = useRef<any>();  // bez <any> jest błąd że może być null
  
  // const [myData, setMyData] = useState<any>(1); // pole z przykładu do zwracania danych wynikowych

  const [state, dispatch] = React.useReducer(exampleReducer, initialState)
  const { loading, results, value, displaySpinner } = state; // state destructuring

  const timeoutRef: any = React.useRef()
  const handleSearchChange = React.useCallback((e, data) => {
    clearTimeout(timeoutRef.current)
    
    if (debug_mode)
    {
      console.log('czyszczenie outputData - linia 203:');
    }

    // setOutputData(undefined); // usunięcie poprzednich danych współrzędnych
    onOsmChange(undefined);

    dispatch({ type: 'START_SEARCH', query: data.value })

    //console.log("zdarzenie handleSearchChange:");
    inputText = data.value;
    // source = []; // czyszczenie tutaj nic nie daje

   //console.log(data.value); // wyświetlenie wartości pola tekstoweg //
    
    /* ========== pobranie danych z axios-a */
    // nadpisanie tablicy : source = [{}];
    // source = getSearchData(data.value); // załadowanie nowych danych
    
      /* ==================================== */

    timeoutRef.current = setTimeout(async () => {
      
      if (debug_mode)
      {
        console.log('pobranie danych z API dla:'); // dobrze, jest po zwłoce
        console.log(inputText);
      }

      dispatch({ type: 'START_SPINNER' }); // dopisane
      await loadAutocompleteData(inputText); // musi być z await
     
      dispatch({ type: 'STOP_SPINNER' });
     
      if (inputText != '') // nie otwierać na siłę np. po skasowaniu pola
        refAC.current.open(); // wymuszenie otwarcia listy results, nawet jeśli pole utraciło focus

      if (data.value.length === 0) {
        dispatch({ type: 'CLEAN_QUERY' })
        return
      }

      const re = new RegExp(_.escapeRegExp(data.value), 'i')
      const isMatch = (result) => re.test(result.title)

      dispatch({
        type: 'FINISH_SEARCH',
        results: source, // wtedy łapie poniatów 97-330, poniatów łódzkie
        //results: _.filter(source, isMatch), // z filtrem już nie łapie
      })
    }, 1000) // może zostać ten 1000, konfiguracja debounce, po tym czasie ustania pisania wysyła post-a po dane
  }, [])
  React.useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [])

  const [placeHolderText, setPlaceHolderText] = useState<string>("cała Polska");

  return (
    // <Grid> z gridem pola są za krótkie
    //   <GridColumn width={6}>
        <Search
          
          ref = {refAC} // używane do otwierania listy results nawet po Blur jak przyjdą dane                 

          loading={displaySpinner} /* w oryginale było tutaj loading */
          showNoResults={loading ? false : true} /* bug solution */
          placeholder={placeHolderText} // 'np. Ełk, Gaj 22-330'  // cała Polska
          
          onFocus ={()=>{setPlaceHolderText('np. Ełk, Łęg 42-282')}} // mógłby być dodaktowy styl

          onResultSelect={(e, data) => { // ZACHOWANIE WYNIKU, CALEGO OBIEKTU: data.result
                        
            outputObject = data.result;

            if (debug_mode)
            {
              console.log('cały zwracany outputObject po przygotowaniu:');
              console.log(outputObject);
            }

            // przepisanie obiektu zwracanego, wg. linia 103
            let tmpOutput: IOsmACReturnedData = {
              name: outputObject.title, // nagłówek item-a
              description: outputObject.description, // ten sam opis co w polu select
              lat: outputObject.lat,
              lon: outputObject.lon,
              // dodatkowe na przyszłość: 
              municipality: outputObject.municipality,
              zip: outputObject.zip,
              county: outputObject.county,
              province: outputObject.province
            }

            if (debug_mode)
            {
              console.log('zwracany wynikowy obiekt do parent-a:');
              console.log(tmpOutput);
            }

            // zwrócenie event emit
  
            onOsmChange(tmpOutput);  // wywołanie emitter-a

            // setOutputData( tmpOutput ); // z nawiasami { } byłby zagnieżdżony
            //setMyData(data.result);
            dispatch({ type: 'UPDATE_SELECTION', selection: data.result.title })
          }
          }
          onSearchChange={handleSearchChange}
          
          results={results}
          value={value}
          
          noResultsMessage="Brak wyniku" // podstawowa 
          noResultsDescription="Miejsce nie zostało znalezione" // mniejszy tekst

          onBlur = {() => {setPlaceHolderText('cała Polska'); onOsmBlur();}}
        />
    //   </GridColumn>
    //       {/* podgląd obiektu data:
    //       {JSON.stringify(myData)} */}
    // </Grid>
  )
}

export default OsmAutoComplete

/* === poprawka spinner-a:
  - usunięcie loading true sprawia że pojawia się w czasie wpisywania również komunikat "brak wyników"
  - rozwiązane przez dodanie zmiennej stanu displaySpinner
*/

/* === przekazywanie danych do parent-a
 - początkowo zwracanie danych było przez przekazywanie funkcji set zmiennej useEffect, ale zostało zmienione na emitowanie zdarzenia, musi takie zostać z undefined przy każdym wciskaniu klawisza
*/

/* === +zagadnienie open listy kiedy komponent nie ma focusa, a axios zwraca dane:
 - jeżeli w czasie mielenia requesta straci blur, to menu się nie otwiera
 problem z open: jeżeli już jest bez focus-a && wyświetli results list, to po kliknięciu obok ten results nie znika, tzn. nie wysyła onBlur-a, a cały czas jest podświetlony jako aktywny
 ale udało się to zrobić bez js-a, przez użycie ref-a, a później wyrzucenie go na konsolę, nie umożliwia setFocus jak typowy od pola input, ale ma funkcję open - która robi to co potrzeba
*/