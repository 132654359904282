/* klucze muszą mieć numeryczne wartości w celu szybszych wyszukiwań */

/* dodane sortowanie */

export const vehicle_colors = [
  { key: 1, value: 1, text: "biały" },
  { key: 2, value: 2, text: "brązowy" },
  { key: 3, value: 3, text: "czarny" },
  { key: 4, value: 4, text: "czerwony" },  
  { key: 5, value: 5, text: "niebieski" },
  { key: 6, value: 6, text: "srebrny" },
  { key: 7, value: 7, text: "szary" },
  { key: 8, value: 8, text: "zielony" },
  { key: 9, value: 9, text: "żółty i złoty" },
  { key: 10, value: 10, text: "inny ..." } // nie zmieniać
];

export const vehicle_colors_4search = [
  //{ key: 0, value: -1, text: "*" }, // dodany - nie potrzebny przy cleared
  { key: 1, value: 1, text: "biały" },
  { key: 2, value: 2, text: "brązowy" },
  { key: 3, value: 3, text: "czarny" },
  { key: 4, value: 4, text: "czerwony" },  
  { key: 5, value: 5, text: "niebieski" },
  { key: 6, value: 6, text: "srebrny" },
  { key: 7, value: 7, text: "szary" },
  { key: 8, value: 8, text: "zielony" },
  { key: 9, value: 9, text: "żółty i złoty" },
  { key: 10, value: 10, text: "inny" } // bez kropek, docelowo rozwinąć
];

export default { vehicle_colors, vehicle_colors_4search};


