// lepiej kiedy są pogrupowane:

 // ! lub zrobić to tak jak w car_brands: pierwszy element tablicy jest usuwany w create Adv form
 // ewent. użyć spread-a

const car_fuel_types = [  
  { key: 1, value: 1, text: "benzyna" },
  { key: 2, value: 2, text: "benzyna, hybryda" },
  { key: 3, value: 3, text: "benzyna, hybryda plug-in" },  
  { key: 4, value: 4, text: "benzyna + LPG" },
  { key: 5, value: 5, text: "benzyna + CNG" },
  { key: 6, value: 6, text: "olej napędowy" },
  { key: 7, value: 7, text: "olej napędowy, hybryda" },
  { key: 8, value: 8, text: "olej napędowy, hybryda plug-in" },  
  { key: 9, value: 9, text: "olej napędowy + LPG" },
  { key: 10, value: 10, text: "olej napędowy + CNG" },  
  { key: 11, value: 11, text: "prąd elektryczny" },
  { key: 12, value: 12, text: "inne" }
];

export default car_fuel_types; 

/* ORYGINALNE:
benzyna
diesel
LPG
benzyna+lpg
elektryczny
*/

/* v2:    // pytanie co z hybrydami ? inne: np. wodór, bez określania
benzyna
olej napędowy
benzyna+LPG
olej napędowy+LPG
benzyna, hybryda
olej napędowy, hybryda
prąd elektryczny
hybryda plug-in
benzyna+CNG
olej napędowy+CNG
inne
*/
