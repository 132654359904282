import React from 'react'

const TestPrivatePage = () => {
  return (
    <div>
      Strona prywatna, wymagająca zalogowania
    </div>
  )
}

export default TestPrivatePage