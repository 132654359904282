import React from "react";
import { useFormikContext } from "formik"

import * as Checkbox from '@radix-ui/react-checkbox';

import { Icon } from "semantic-ui-react";

/* można też nadać id, oraz później labelFor, wtedy kliknięcie w label
  jest równoważne kliknięciu w polu checkbox-a
*/

const RadixCheckBox = (props) => {
  const { name, label, default_checked, ...rest } = props;
  const formik = useFormikContext();
  
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Checkbox.Root defaultChecked={default_checked} className="CheckboxRoot"
        onCheckedChange={ (e) => { 
          formik.setFieldTouched(name, true); // zawsze na true
          formik.setFieldValue(name, e);
          }
        }       
      >
        <Checkbox.Indicator className="CheckboxIndicator">
          <Icon name='check' size='small' style={{color: '#00ff00', paddingLeft: '7px', paddingBottom: '3px'}} />
        </Checkbox.Indicator>
      </Checkbox.Root>
      <label className={formik.errors[name] && formik.touched[name] ? "radixCheckboxErrorLabel" : "radixCheckboxLabel"}>
        {label}
      </label>
    </div>
   
) // nawias dla return

} // arrow function komponentu

export default RadixCheckBox