import { createContext, useContext } from 'react';
import { useMediaQuery } from 'react-responsive';

{/* użycie: 
  pierwsza linia: const { sizeMobile } = useScreenSize();  // wczytanie z providera obiektu sizeMobile
  w tsx: { !sizeMobile ? ('size mobile') : ('NIE SIZE MOBILE') }
*/}

const ScreenSizeContext = createContext<any>('');  // musi być any

export default function ScreenSizeProvider({ children }) { 
  
  let sizeMobile = useMediaQuery({ query: "(min-width:767px)" });  // BELOW 768
  // bez obiektu, bo tylko 1

  return (
      <ScreenSizeContext.Provider value={{sizeMobile}}> 
          {children}
      </ScreenSizeContext.Provider>
  );
}


export function useScreenSize() {  // nazewnictwo jako hook
    return useContext(ScreenSizeContext)
}

