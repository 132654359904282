/* SCENARIUSZE, funkcjonalność testowana:
  do tego request wymagany token, z niego wczytanie login-u do operacji na bazie
  
  + walidacja yup formy
  + złe aktualne hasło + poprawna walidacja
  + wygasły token - odpowiedź od serwera

  - poprawna zmiana hasła - komunikat /msg
  [Z API]:
  403 - błędne aktualne hasło, message: 'actual-passwor'
  404 - błąd zapisu w bazie
  401 - coś nie tak z tokenem
  404 - zły format nowego hasła
*/

import { FormikProvider, useFormik} from "formik"; // bez form nie łapie submit
import {
  Form,
  Grid,
  Message,
  Segment
} from "semantic-ui-react";

import { Icon } from "semantic-ui-react";
import FormikControl from "../../common/form-controls/FormikControl";
import React, { useState } from 'react';

import FormikButton from "../../common/form-controls/FormikButton";

import * as Yup from "yup";
import { Users } from "../../api/agent";

// import convertError from "../../common/form-controls/ConvertError"

const UpdatePasswordForm = ({history}) => {
  const [wrongActualPasswords, setWrongActualPasswords] = useState(new Array<string>());

  function handleFormSubmit(values: any) {
    // axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('token')}`}
    
    Users.updatePassword(values)
      .then(function (response) {
        // 200 ok - tzn. udało się

        history.push({
          pathname: '/msg',
          state: {
            type: 'success',
            message: response
          }
        }); 

      })
      .catch(function (error) {
        // console.log(error);

        formik.setSubmitting(false); // KONIECZNE, odblokowanie przycisku do następnej próby

        if (error.response === undefined) // brak połączenia z serwerem, musi być na samej górze
        {
          history.push({
            pathname: '/msg',
            state: {
              type: 'error',
              message: 'Wystąpił błąd połączenia z serwerem.'
            }
          });
        }

        if (error.response.status === 403 && error.response.data.message === 'actual-password') // unauthorized , 403 dla nie aktywowane
        {          
          formik.setFieldError("actualPassword", "aktualne hasło jest błędne"); // nadpisuje 409 do pola
          setWrongActualPasswords(oldArray => [...oldArray, formik.values.actualPassword]); // tak zeby ten błąd nie był wygaszony przy blur       
          return; // inaczej ustawi actual-password na statusie, na dole formy
        }

        // pozostałe błędy dopisać na dole formy // 
        formik.setStatus(error.response.data.message);
      }); // axios catch    
     
      // ~~ axios request

  } // ~ handle submit
  
  const formik = useFormik({
    // 3 args
    initialValues: {
      actualPassword: "",
      newPassword: "",
      retypedNewPassword: "",
      form_error: false    /* komunikat zbiorczy */
    },
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
    validationSchema: Yup.object({
      actualPassword: Yup.string().notOneOf(wrongActualPasswords, 'aktualne hasło jest błędne').required(''),
      newPassword: Yup.string().required('').max(100, 'maksymalnie 100 znaków').matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/, 'wymagane 8 znaków, w tym litera i cyfra'),
      retypedNewPassword: Yup.string().required('').oneOf([Yup.ref("newPassword")], "hasła są różne")
    }),
  });

  return (
    <Grid>
      <Grid.Column mobile={1} tablet={2} computer={4}>
        &nbsp;
      </Grid.Column>
      <Grid.Column mobile={14} tablet={12} computer={8}>
        <Segment clearing>
          {/* <button onClick={setFieldError}>set field error</button>
          <button onClick={setFormError}>set form error</button> */}
          <FormikProvider value={formik}>
            <div>
              <Message attached header="Formularz zmiany hasła" />
              <Form
                onSubmit={formik.handleSubmit}
                className="attached fluid segment"
                onChange={()=>{formik.setStatus('');}} /* wyczyszczenie komunikatu */
              >
                <FormikControl 
                  control='input'
                  name='actualPassword'
                  placeholder='aktualne hasło'
                  icon="key"
                  iconPosition="left"
                  type="password"
                /><br/>
                <FormikControl 
                  control='input'
                  name='newPassword'
                  placeholder='nowe hasło'
                  icon="key"
                  iconPosition="left"
                  type="password"                  
                /><br/>
                <FormikControl 
                  control='input'
                  name='retypedNewPassword'
                  placeholder='powtórzone nowe hasło'
                  icon="key"
                  iconPosition="left"
                  type="password"                  
                /><br/>
                <FormikButton
                  text="Zapisz"
                  icon="save"
                  name="submit1"
                  type="submit"
                  positive={true}
                  fluid={true}
                  disabled={!(formik.isValid && formik.dirty) || !!(formik.status) || formik.isSubmitting}
                />
              </Form>
              {formik.status && (
                <Message
                  attached="bottom"
                  error
                  style={{ textAlign: "centered" }}
                >
                  <Icon name="exclamation" />
                  { formik.status }
                </Message>
              )}
            </div>
          </FormikProvider>
        </Segment>
      </Grid.Column>
    </Grid>
  );
};

export default UpdatePasswordForm;
