/* SCENARIUSZE, funkcjonalność:
  + zły login / email - 404
  + konto już aktywowane - 403
  + udane wysłane wiadomości + komunikat z api
  + blokowanie przycisku
  ? problem z mailerem - 404, pod formą
  + aktywacja ponowną wiadomością aktywuje
*/

import { FormikProvider, useFormik } from "formik"; // bez form nie łapie submit
import {
  Form,
  Grid,
  Message,
  Segment
} from "semantic-ui-react";

import { Icon } from "semantic-ui-react";
import FormikControl from "../../common/form-controls/FormikControl";

import * as Yup from "yup";

import FormikButton from "../../common/form-controls/FormikButton";
import { Users } from "../../api/agent";

const ResendActivationMailForm = ({history}) => {

  const formik = useFormik({
    // 3 args
    initialValues: {
      email: "",
      form_error: false    /* komunikat zbiorczy */
    },
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
    validationSchema: Yup.object({
      email: Yup.string().required('').email(' ')
    }),
  });

  const handleFormSubmit = (values) => {
    // axios request
  
    Users.resendActivationMail(values)
      .then(function (response: any) {
        // 200 ok - tzn. udało się wysłać wiadomość
  
        // przekierowanie /msg
        history.push({
          pathname: '/msg',
          state: {
            type: 'success',
            message: response
          }
        });
  
      })
      .catch(function (error) {
        formik.setSubmitting(false); // KONIECZNE, odblokowanie przycisku do następnej próby
  
        // console.log(error);
  
        if (error.response === undefined) // brak połączenia z serwerem, musi być na samej górze
        {
          formik.setStatus('Wystąpił błąd połączenia z serwerem');
        } else { // 404, 403
          formik.setStatus(error.response.data.message);
        }
      }) // ~catch
  }; // ~handle submit

  return (
    <Grid>
      <Grid.Column mobile={1} tablet={2} computer={4}>
        &nbsp;
      </Grid.Column>
      <Grid.Column mobile={14} tablet={12} computer={8}>
        <Segment clearing>
          {/* <button onClick={setFieldError}>set field error</button>
          <button onClick={setFormError}>set form error</button> */}
          <FormikProvider value={formik}>
            <div>
              <Message attached header="Ponowne wysłanie wiadomości aktywacyjnej" />
              <Form
                onSubmit={formik.handleSubmit}
                className="attached fluid segment"
                onChange={()=>{formik.setStatus('');}} /* wyczyszczenie komunikatu */
              >
                <FormikControl 
                  control='input'
                  name='email'
                  placeholder='adres e-mail'
                  icon="user"
                  iconPosition="left"
                /><br/>
                <FormikButton
                  text="Wyślij ponownie"
                  icon="mail"
                  name="submit1"
                  type="submit"
                  positive="true"
                  fluid="true"
                  disabled={!(formik.isValid && formik.dirty) || formik.status || formik.isSubmitting}
                />
              </Form>
              {formik.status && (
                <Message
                  attached="bottom"
                  error
                  style={{ textAlign: "centered" }}
                >
                  <Icon name="exclamation" />
                  { formik.status }
                </Message>
              )}
            </div>
          </FormikProvider>
        </Segment>
      </Grid.Column>
    </Grid>
  );
};

export default ResendActivationMailForm;
