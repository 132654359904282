/* w przypadku tras parametrycznych dla braku parametrów robić przekierowanie na /home,
  albo po prostu opcjonalne parametry, tj. ten sam komponent, tam warunek if czy są wszyskite, jeśli nie - przekierowanie

  jednak container na tym poziomie powoduje problemy na ekranie mobile - nie jest rozciągany, dla przykładowo formy login && width 650
*/

/* ========= IMPORTY CSS ========= */
import './css/_GLOBAL-vars.css';
import './css/index.css'; // ok, tylko 2 style

import './css/unthemed/layout/resetting.css'; // same style restowania, ewentualnie odpiąć żeby zobaczyć do czego są te style
import './css/unthemed/layout/layout.css'; // ok, 5rem, jedynie układ layout-u, tj. układ strony
import './css/unthemed/form.css'; // układ pól form
import './css/unthemed/search-form.css'; // porpawki dla formy search
import './css/unthemed/register-form.css'; // porpawki dla formy search

import './css/themed/body.css'; // tag body, background, czcionka

import './css/themed/prompt.css'; // komunikaty, też do pól
import './css/themed/texts.css'; // 

import './css/themed/form/bottom-message.css'; // ok
import './css/themed/form/box.css'; // ok
import './css/themed/form/caption.css'; // nagłówek formularza

import './css/themed/form/controls/button.css'; // też te w linii pod formą
import './css/themed/form/controls/dropdown-menu.css'; // ok 
// import './css/themed/form/controls/map.css';
import './css/themed/form/controls/radix-checkbox.css'; // ok
import './css/themed/form/controls/recaptcha.css'; // ok
import './css/themed/form/controls/select.css'; // ok
import './css/themed/form/controls/text-input-textarea.css'; // ok
import './css/themed/form/controls/location.css'; // ok
import './css/themed/form/controls/pagination.css'; // ok

import './css/themed/navbar.css'; // ok
import './css/themed/footer.css'; // footer, tło, czcionka
import './css/themed/search-results.css'; // wyniki wyszukiwania-lista

import './css/themed/form/controls/osm-ac.css'; // lista osmAutoComplete
import './css/themed/form/group-caption.css';

/* ========= ~IMPORTY CSS ========= */

// import './css/index.css';
// import './css/themed/dropdown-menu.css';
// import './css/themed/attached-form-message.css'; /* bez zmiennych */
// import './css/themed/text-field.css'; /* nie przeniesione zmienne */
// import './css/themed/buttons.css';
// import './css/themed/form-box.css';
// import './css/themed/prompts.css';
// import './css/themed/radix-checkbox.css';

// const css = import.meta.globEager("./*.css");
// ==============================================

import React, { Fragment } from 'react';
import NavBar from './features/navbar/NavBar';
import RegisterForm from './features/users/RegisterForm'; // ok, api-ok
import LoginForm from './features/users/LoginForm'; // ok
import UpdatePasswordForm from './features/users/UpdatePasswordForm'; // ok
import SendResetPasswordMailForm from './features/users/SendResetPasswordMailForm'; // ok
import ResendActivationMailForm from './features/users/ResendActivationMailForm'; // ok
import ResetPasswordForm from './features/users/ResetPasswordForm'; // ok
import ActivateAccountPage from './features/users/ActivateAccountPage'; // ok

import Message from './features/alerts/Message'; // ok

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ScrollToTop from './common/helpers/ScrollToTop';
import TestPrivatePage from './features/pages/TestPrivatePage';
import PrivateRoute from './common/contexts/PrivateRoute';
import UserProvider from './common/contexts/UserProvider';

import CreateAdvForm from './features/advs/CreateAdvForm';
// import PublicOnlyRoute from './common/contexts/PublicOnlyRoute';

import SearchAdvsResult from './features/advs/SearchAdvsResults';
import OwnAdvsList from './features/advs/OwnAdvsList';
import ViewAdvCar from './features/advs/ViewAdvCar';
import SearchAdvsForm from './features/advs/SearchAdvsForm';
import CheckEnv from './features/playgroundTests/CheckEnv';

import Footer from './features/footer/Footer';
import Test from './features/tests/test';
import ScreenSizeProvider from './common/contexts/ScreenSizeProvider';
import ThemeProvider2 from './common/contexts/ThemeProvider2';

function App() {

  return (
    <Fragment>
      <UserProvider>
        <ThemeProvider2>
          <ScreenSizeProvider>
            <BrowserRouter>{/*55 na 0 */}
              <div id="page-container">
                  <NavBar /><div id="content-wrap">
                  <div className="bodyContentWrapper">{/* ustawieny tutaj jest globalny padding */}
                    <ScrollToTop>
                      <Switch>{/* gotowe: SearchAdvForm, SearchAdvsResult, OwnAdvsList" " HomePageMain CreateAdvForm ;; CreateAdvForm ;; ViewAdvCar*/} 
                        <Route path='/advs/cars/search/:query' component={ SearchAdvsResult } exact></Route>
                        <Route path='/advs/cars/view/:id' component={ ViewAdvCar } exact></Route> 
                        <Route path='/' component={ SearchAdvsForm } exact></Route>
                        <Route path='/advs/search' component={ SearchAdvsForm } exact></Route>
                        <Route path='/users/activate/:userId/:hashCode' component={ActivateAccountPage} exact></Route>
                        <Route path='/msg' component={Message} exact></Route>
                        <Route path='/users/register' component={RegisterForm} exact></Route>
                        <Route path='/users/login' component={LoginForm} exact></Route>
                        <Route path='/users/reset-password/:userId/:hashCode' component={ResetPasswordForm} exact></Route>
                        <Route path='/users/remind' component={SendResetPasswordMailForm} exact></Route>
                        <Route path='/users/resend-activation-mail' component={ResendActivationMailForm} exact></Route>
                        <Route path='/checkenv' component={ CheckEnv }></Route>

                        <Route path='/test' component={ CheckEnv }></Route>

                        <Route path='/private'>
                          <PrivateRoute><TestPrivatePage /></PrivateRoute>
                        </Route>
                        <Route path="*">
                          <PrivateRoute>
                            <Switch>
                              <Route path='/advs/cars/create' component={CreateAdvForm}></Route>
                              <Route path="/profile/update-password" component={UpdatePasswordForm} />
                              <Route path='/profile/own-advs' component={OwnAdvsList}></Route>
                              
                              <Route path="/private2" component={TestPrivatePage} />
                              <Route path="/private3" component={CreateAdvForm} />
                            </Switch>
                          </PrivateRoute>
                        </Route>
                      </Switch>
                    </ScrollToTop>
                    </div>
                  <Footer/>          
                </div>
              </div>{/* #page-container */}
            </BrowserRouter>
          </ScreenSizeProvider>
        </ThemeProvider2>
      </UserProvider>
    </Fragment>
  );
}

export default App;

/* footer musi być poza container, żeby był od boku do boku */