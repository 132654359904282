/* klucze muszą mieć numeryczne wartości w celu szybszych wyszukiwań */

/* usuwać pola o ujemnej wartości w formularzu "nowe ogłoszenie" - lub ich nie dopuszczać w walidacji */

/* -2, -3 to są pola wyłączone */

/* UWAGA:
  - pierwszy element jest usuwany po indeksie 0 w formularzu create adv 
  - indeks elementu "inna" jest hard-coded w onChange create adv form, tj. tam warunkowo jest wyświetlane pole inna


  UWAGI EDYCJA:
  - nową markę dopisać na samym końcu, z największym numerem key, tj. 88
  - w search api "/cars/search" jest do edycji warunek wyszukiwania dla Główne |-2 [1-37] / Inne |-3, tj [38, 87]:
  - w create adv jest warunek na pole inne, -2, -3 :walidacja, -1 :ukryte
  - pierwszy element jest zupełnie usuwany w Create Adv
*/

const car_brands = [  // JAK JEST TEN ZNACZNIK CLEAR TO NIE MA PO CO DAWAĆ PUSTEGO ELEMENTU NA SEMEJ GÓRZE */
  // { key: -1, value: -1, text: ""},  // ! pierwszy element tablicy jest usuwany w create Adv form
  // NAJBARDZIEJ POPULARNE: // 
  { key: -2, value: -2, text: "= GŁÓWNE: =", disabled: true },

  { key: 1, value: 1, text: "Alfa Romeo"},
  { key: 2, value: 2, text: "Audi"},
  { key: 3, value: 3, text: "Bentley"},
  { key: 4, value: 4, text: "BMW"},
  { key: 5, value: 5, text: "Chevrolet"},
  { key: 6, value: 6, text: "Chrysler"},
  { key: 7, value: 7, text: "Citroën"},
  { key: 8, value: 8, text: "Cupra"},
  { key: 9, value: 9, text: "Dacia"},
  { key: 10, value: 10, text: "Daewoo"}, 
  { key: 11, value: 11, text: "Fiat"},
  { key: 12, value: 12, text: "Ford"},
  { key: 13, value: 13, text: "Honda"},
  { key: 14, value: 14, text: "Hyundai"},  
  { key: 15, value: 15, text: "Jaguar"},
  { key: 16, value: 16, text: "Jeep"},
  { key: 17, value: 17, text: "Kia"},
  { key: 18, value: 18, text: "Land Rover"},
  { key: 19, value: 19, text: "Lexus"},
  { key: 20, value: 20, text: "Mazda"},
  { key: 21, value: 21, text: "Mercedes"},
  { key: 22, value: 22, text: "Mini"},
  { key: 23, value: 23, text: "Mitsubishi"},
  { key: 24, value: 24, text: "Nissan"},
  { key: 25, value: 25, text: "Opel"},
  { key: 26, value: 26, text: "Peugeot"},
  { key: 27, value: 27, text: "Porsche"},
  { key: 28, value: 28, text: "Renault"},
  { key: 29, value: 29, text: "Seat"},
  { key: 30, value: 30, text: "Skoda"},
  { key: 31, value: 31, text: "Smart"},  
  { key: 32, value: 32, text: "Suzuki"},
  { key: 33, value: 33, text: "Tesla"},
  { key: 34, value: 34, text: "Toyota"},
  { key: 35, value: 35, text: "Volkswagen"},
  { key: 36, value: 36, text: "Volvo"},
  { key: 37, value: 37, text: "Vauxhall"},

  // ~ NAJBARDZIEJ POPULARNE

  { key: -3, value: -3, text: "= POZOSTAŁE: =", disabled: true},

  { key: 38, value: 38, text: "Abarth"},
  { key: 39, value: 39, text: "A.C."},
  { key: 40, value: 40, text: "Acura"},
  { key: 41, value: 41, text: "Alpine"},
  { key: 42, value: 42, text: "Aston Martin"},
  { key: 43, value: 43, text: "BMW Alpina"},
  { key: 44, value: 44, text: "Bugatti"},
  { key: 45, value: 45, text: "Buick"},
  { key: 46, value: 46, text: "Cadillac"},
  { key: 47, value: 47, text: "Caterham"},
  { key: 48, value: 48, text: "Daihatsu"},
  { key: 49, value: 49, text: "Datsun"},
  { key: 50, value: 50, text: "Dodge"},
  { key: 51, value: 51, text: "DS Automobiles"},
  { key: 52, value: 52, text: "Ferrari"},
  { key: 53, value: 53, text: "GAZ"},
  { key: 54, value: 54, text: "Genesis"},
  { key: 55, value: 55, text: "Ginetta"},
  { key: 56, value: 56, text: "GMC"},
  { key: 57, value: 57, text: "Holden"},
  { key: 58, value: 58, text: "Infiniti"},
  { key: 59, value: 59, text: "Isuzu"},
  { key: 60, value: 60, text: "Koenigsegg"},
  { key: 61, value: 61, text: "Lada"},
  { key: 62, value: 62, text: "Lamborghini"},
  { key: 63, value: 63, text: "Lancia"},
  { key: 64, value: 64, text: "Lincoln"},
  { key: 65, value: 65, text: "Lotus"},
  { key: 66, value: 66, text: "Mahindra"},
  { key: 67, value: 67, text: "Maruti Suzuki"},
  { key: 68, value: 68, text: "Maserati"},
  { key: 69, value: 69, text: "McLaren"},
  { key: 70, value: 70, text: "Melex"},
  { key: 71, value: 71, text: "Morgan"},
  { key: 72, value: 72, text: "Pontiac"},
  { key: 73, value: 73, text: "Proton"},
  { key: 74, value: 74, text: "Rolls Royce"},
  { key: 75, value: 75, text: "Rover"},
  { key: 76, value: 76, text: "Saab"},
  { key: 77, value: 77, text: "Samsung"},
  { key: 78, value: 78, text: "Spyker"},
  { key: 79, value: 79, text: "SsangYong"},
  { key: 80, value: 80, text: "Subaru"},
  { key: 81, value: 81, text: "Tata"},
  { key: 82, value: 82, text: "UAZ"},
  { key: 83, value: 83, text: "WAZ"},
  { key: 84, value: 84, text: "ZAZ"},
  { key: 85, value: 85, text: "Zenvo"},
  { key: 86, value: 86, text: "- zabytkowe -"},
  { key: 87, value: 87, text: "inna ..."} // UWAGA: wartość hard coded w create adv, warunkowym wyświetlaniu pola
];

export default car_brands;