import React, { Fragment } from 'react'
import { useMediaQuery } from 'react-responsive';
import { Header } from 'semantic-ui-react'

const Footer = () => {
  const sm = useMediaQuery({ query: "(min-width:420px)" }); // deklaracja, usunięcie textu obok logo

  const size = { sm }

  return (
    <footer id="footer" style={{ textAlign: "center", paddingTop: "20px", paddingBottom: "20px" }}>
      <div className="footerCssWrapper">
        <Header as='h4'>
          motokomis.pl - używane auta - darmowe ogłoszenia motoryzacyjne
          
          <a href="https://www.cutercounter.com/" target="_blank" style={{visibility: "hidden"}}>
            <img src="https://www.cutercounter.com/hits.php?id=hvxnknfk&nd=4&style=8" height="16" alt="" />
          </a>

          <br /><br />

        </Header>
      </div>
    </footer>
  )
}

export default Footer