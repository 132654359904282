/* SCENARIUSZE: funkcjonalność, testy: 
  - błędny link i od razu przekierowanie
  + błędny lub wykorzystany link : /msg
  + poprawny link : /msg

  opis: zebranie z get params HASH CODE, przekazanie go później w POST,

  => już w ComponentDidMount powinien być request sprawdzający czy link jest poprawny, tzn. po co inaczej user ma wykonywać hasło ...
*/

import { FormikProvider, useFormik} from "formik"; // bez form nie łapie submit
import {
  Form,
  Grid,
  Message,
  Segment
} from "semantic-ui-react";

import { Icon } from "semantic-ui-react";
import FormikControl from "../../common/form-controls/FormikControl";

import * as Yup from "yup";

import React, { useEffect } from 'react';

import { Users } from "../../api/agent";

const ResetPasswordForm = (props: any) => {
 
  useEffect(() => {  // component did mount - sprawdzenie poprawności linka
    const userId = props.match.params.userId;
    const hashCode = props.match.params.hashCode;

    const data2Send = {
      userId: userId,
      hashCode: hashCode
    }

    // axios
    Users.checkRpLink(data2Send)
    .then(function (response) {
      // 200 ok - tzn. udało się, link poprawny - nic nie robić

    })  // ~ then
    .catch(function (error) { // błędny link - przekierować

      if (error.response === undefined) // brak połączenia z serwerem, musi być na samej górze
      {
        props.history.push({
          pathname: '/msg',
          state: {
            type: 'error',
            message: 'Wystąpił błąd połączenia z serwerem.'
          }
        });
      } // ~ if

      props.history.push({
        pathname: '/msg',
        state: {
          type: 'error',
          message: error.response.data.message
        }
      });           
    }); // ~ axios
  }, []); // ~ component did mount
 

  function handleFormSubmit(values: any) {
    /* link do resetowania hasła:  /users/reset-password/' + userId + '/' + hashCode; */
    const sentData = {
      userId: props.match.params.userId,       /* skąd brać ten login ? */ // w zasadzie userId
      newPassword: values.newPassword,
      hashCode: props.match.params.hashCode
    }

    console.log(sentData);

    Users.resetPassword(sentData)
      .then(function (response) {
        props.history.push({
          pathname: '/msg',
          state: {
            type: 'success',
            message: response
          }
        });
    }) 
  .catch(function (error) {    
    if (error.response === undefined) // brak połączenia z serwerem, musi być na samej górze
    {
      props.history.push({
        pathname: '/msg',
        state: {
          type: 'error',
          message: 'Wystąpił błąd połączenia z serwerem.'
        }
      });
    }

    // cała reszta - przekierowanie na error

    props.history.push({
      pathname: '/msg',
      state: {
        type: 'error',
        message: error.response.data.message
      }
    });   

  }); // ~ axios catch
} // handle submit

  const formik = useFormik({
    // 3 args
    initialValues: {
      newPassword: "",
      retypedNewPassword: "",
      form_error: false    /* komunikat zbiorczy */
    },
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
    validationSchema: Yup.object({
      newPassword: Yup.string().required('').max(100, 'maksymalnie 100 znaków').matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/, 'wymagane 8 znaków, w tym litera i cyfra'),
      retypedNewPassword: Yup.string().required('').oneOf([Yup.ref("newPassword")], "hasła są różne")
    }),
  });

  return (
    <Grid textAlign='center' verticalAlign='middle' style={{height: '100%'}}>
      <Grid.Column mobile={14} tablet={12} computer={8}>
        <Segment clearing>
          {/* <button onClick={setFieldError}>set field error</button>
          <button onClick={setFormError}>set form error</button> */}
          <FormikProvider value={formik}>
            <div>
              <Message attached header="Formularz zmiany hasła" />
              <Form
                onSubmit={formik.handleSubmit}
                className="attached fluid segment"
              >
                <FormikControl 
                  control='input'
                  name='newPassword'
                  placeholder='nowe hasło'
                  icon="key"
                  iconPosition="left"
                  type="password"                  
                /><br/>
                <FormikControl 
                  control='input'
                  name='retypedNewPassword'
                  placeholder='powtórzone nowe hasło'
                  icon="key"
                  iconPosition="left"
                  type="password"                  
                /><br/>
                <Form.Button
                  icon
                  fluid
                  positive
                  type="submit"
                  disabled={!(formik.isValid && formik.dirty)}
                >
                  <Icon name="save" />&nbsp;&nbsp;Zmień hasło
                </Form.Button>
              </Form>
              {formik.errors.form_error && (
                <Message
                  attached="bottom"
                  error
                  style={{ textAlign: "centered" }}
                >
                  <Icon name="exclamation" />
                  Błędny login, e-mail lub hasło
                </Message>
              )}
            </div>
          </FormikProvider>
        </Segment>
      </Grid.Column>
    </Grid>
  );
};

export default ResetPasswordForm;
