/* a component wrapper, from 5/9 Megatutorial, completed 
 to tutaj należy sprawdzać czy token nie jest wygasły, z użyciem jwt
 bo inaczej zawsze w local storage będzie już ten wygasły token, 
 a wtedy trzeba będzie się WYLOGOWAĆ żeby go usunąć i przestały przychodzić 401

*/

/* TESTY:
  + wygasły token - przekierowanie z informacją "wygasła sesja"
  + uruchomienie rout-y bez zalogowania - przekierowanie do logowania + komunikat + powrót
  + refresh - do tego potrzeba zawsze ustawiać obiekt user na pasek, bo ten jest tracony
*/

/* eslint-disable */

import { Redirect, useLocation } from 'react-router-dom';
import jwt from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { useUser } from '../../common/contexts/UserProvider'; // wymagane do odświeżania
import { Fragment }  from 'react';

const PrivateRoute = ({ children }) => {  
    
    const currentLocation = useLocation();  // hook provide from react-router-dom

    const [checkTokenVar, setCheckTokenVar] = useState(null);

    const {checkToken, setUser} = useUser();    

    useEffect(() => { 

      const tokenValue = checkToken(); // musi być tu, bo zawiera setState, inaczej jest warning

      setCheckTokenVar(tokenValue);
    }), [];
  

    return (
      <Fragment>
        {(checkTokenVar === 0) && (<Redirect
        to={{
          pathname: "/users/login",
          state: { referrer: currentLocation.pathname, message: 'Wymagane zalogowanie' }
        }} />)}

        {(checkTokenVar === 1) && (<Redirect
        to={{
          pathname: "/users/login",
          state: { referrer: currentLocation.pathname, message: 'Sesja logowania wygasła' }
        }} />)}

        {(checkTokenVar === 2) && children}
       </Fragment>
    )    
  
}    

export default PrivateRoute;

    // const url = location.pathname + location.search + location.hash;

    // sprawdzenie czy token jest w local storage i czy nie jest on wygasły,
    // jeśli jest wygasły - logout wywołać żeby wyczyścić na pasku user-a oraz
    // przekierować do login form, może ze state "sesja wygasła"

    // === SPRAWDZENIE CZY TOKEN JEST:

    /*
    if (user === undefined) { // state not set yet
        return null;
    }
    else if (user) {
        
    }
    else {
        const url = location.pathname + location.search + location.hash; // we save the current url
        return <Navigate to="/login" state={{next: url}} /> // we navigate to login with state, that can be used later in the login component
    }
    */