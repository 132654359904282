/* 
  !!! czyli ponieważ cropper jest wyświetlany warunkowo, musi mieć zewnętrzne pole
  które ładuje do niego dane, tzn. który jest zawsze wyświetlany,
  STĄD MUSI MIEĆ POLA WEJŚCIOWE IPROPS

  KONFIGURACJA cropper-a:
  - załadowanie do scr
  - podanie klasy obiektu div preview
  - obsługa crop po zakończeniu przycięcia - przetworzenie wyniku

  imagePreview - imageForCropper

  były drobne zmiany w handleCrop
*/

// ten komponent w zasadzie jest gotowy, był też uruchamiany już

import React, { Fragment, useRef } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

interface IProps {
  setCroppedImage: (file: Blob) => void; // ?? to chyba wynik
  cropperSrcImage: string; // TO JEST TO CO JEST ŁADOWANE DO CROPPERA PRZEZ SRC
}


const CropperComponent: React.FC<IProps> = ({ setCroppedImage, cropperSrcImage }) => {
  const cropperRef = useRef<any>(null);

  const handleImageCropped = () => {  // ZWRÓCENIE WYNIKU ??

    const cropper = cropperRef.current?.cropper;

    if (!cropper) {
      // console.log('obiekt cropper jest undefined');
      return;
    }

    if (
      typeof cropper.getCroppedCanvas() === 'undefined'  // musi być bez () bo inaczej błąd
    ) {
      // console.log('cropper canvas is undefined'); // cały czas to 
      return;
    }

    cropper.getCroppedCanvas().toBlob((blob: any) => {
        // console.log('ustawiono canvas');  // źródło problemów
        setCroppedImage(blob);  // ZWRÓCENIE WYNIKU
      }, 'image/jpeg');
  };

// TEN KOMPONENT ZOSTAŁ JUŻ UKOŃCZONY

  return (
    <Fragment>
      <Cropper
        preview=".img-preview"
        src={cropperSrcImage}   // tu byla zmiana, ładowanie pliku do edycji
        ref={cropperRef}
        style={{ maxHeight: 400, width: "100%" }}  // height: 250, height można pominąć, ale wtedy cropper potrafi być na prawdę długi 
        zoomTo={0.2}
        initialAspectRatio={5/3}
        aspectRatio={5/3}
        viewMode={1}
        minCropBoxHeight={10}
        minCropBoxWidth={10}
        background={false}
        responsive={true}
        autoCropArea={1}
        checkOrientation={false}
        guides={false}
        crop={handleImageCropped}
      />
    </Fragment> 
  );
};


export default CropperComponent;
