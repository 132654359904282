/* 
  grid 2 kolumnowy, w nim pola 
  Form.Field inline nie działało

  na przycisku dla mobile: marginTop: '25px', marginBottom: '25px'

  ~ rozsuwanie pól: nie div spacer, nie margin na row, jedynie klasa margin na column z polami formy
*/

/* 
  nazwa pól search: // niech będą po polsku //

  rodzaj
  marka

  model
  stan

  miejsce (obiekt)
  dystans 
*/

/* uwagi: div spacer rozbija układ 4 kolumnowy na ekranie computer, podobnie jak wiersz

*/

import React, { useEffect, useState, useRef, Fragment } from 'react'
import { Container, Dropdown, Form, Grid, Header, Message, Segment, Select } from 'semantic-ui-react'
import vehicle_types from '../../common/data/select-options/vehicle_types'

import FormikControl from '../../common/form-controls/FormikControl'
import { Field, FormikProvider } from "formik";
import * as Yup from "yup";
import { useFormik } from "formik";
import car_brands from '../../common/data/select-options/car_brands';

import radius_distance_list from '../../common/data/select-options/radius_distance_list';

import './SearchAdvsForm.css';
import FormikButton from '../../common/form-controls/FormikButton';
import search_vehicle_states from '../../common/data/select-options/search_vehicle_states';
// import useAutoCompleteZip from '../../common/services/autocomplete_zip';
// import useAutoCompleteCity from '../../common/services/autocomplete_city';
import capitalizeEachWord from '../../common/helpers/CapitalizeString';

import { useScreenSize } from '../../common/contexts/ScreenSizeProvider';
import OsmAutoComplete from '../../common/form-controls/Osm/OsmAutoComplete';
import IOsmACReturnedData from '../../common/form-controls/Osm/OsmAutoCompleteDataModel';
import { vehicle_colors_4search } from '../../common/data/select-options/vehicle_colors';
import car_fuel_types from '../../common/data/select-options/car_fuel_types';

let debug_mode = false; if (process.env.REACT_APP_IS_PRODUCTION) debug_mode = false;

let osmData: IOsmACReturnedData | undefined = undefined; // zamiast zmiennej stanu

const SearchAdvsForm = ({ history }) => {

    // const [osmData, setOsmData] = useState<IOsmACReturnedData | undefined>(undefined);
   
  const [isDistDisabled, setIsDistDisabled] = useState<boolean>(true); // do blokowania pola distance kiedy nie wybrano nic w osmAC
    

  // 338
  const handleOsmChange = (arg) => {  // wiele zdarzeń undefined, przy każdym naciśnięciu klawisza
    if (debug_mode)
    {
      console.log('zdarzenie handleOsmChange, arg:')
      console.log(arg);
    }

    osmData = arg;

    if (osmData == undefined)
      setIsDistDisabled(true);
    else 
      setIsDistDisabled(false);

  }; // ma zachodzić kiedy wybrano / wyczyszczono dane

  const { sizeMobile } = useScreenSize();  // wczytanie z providera obiektu sizeMobile

  const [carBrandsArray, setCarBrandsArray] = useState<any[]>([]);

  // przez to że jest values w argumencie trzeba nadpisywać geo dane jako pole formika
  const handleFormSubmit = (values) => {

    //console.log('handleFormSubmit EVENT, form values:');
    //console.log(values);

    if (debug_mode)
    {
      console.log('handleFormSubmit EVENT, form values:');
      console.log(values);
      console.log('osmData z poziomu handleFormSubmit:');
      console.log(osmData);
    }

    // usunięcie wartości "dowolne" - muszą one być czyszczone
    if (values.model === '*') {
      values.model = '';
    }

    // delete values.acInput;  // trzeba usunąć acInput

    // zamiana modelu na UpperCase, tak jak w formularzu Create:
    const modelTmp = values.model.toLowerCase();;

    delete values.model;

    const modelTmpUpper = capitalizeEachWord(modelTmp);

    values.model = modelTmpUpper;

    // === KONWERSJA NA ŁAŃCUCH GET, uwaga: typ jest tracony, tj. number przechodzi w string

    // ==== DOPISANIE DANYCH GEO, jeśli są dane:
    // konwersja danych geo do wysłania:

    if (osmData) {  // jeśli są dane GEO
      values.lat = osmData.lat;
      values.lon = osmData.lon;
      values.kod = ""; // tutaj jest nie potrzebny ?
      values.miasto = osmData.name;
    } else { // czyszczenie
      values.lat = "";
      values.lon = "";
      values.kod = "";
      values.miasto = "";
    }

    // ORYGINALNY KOD z GOOGLE API:
    // if (values.lokalizacja) {
    //   values.lat = values.lokalizacja.lat;
    //   values.lon = values.lokalizacja.lng;
    //   values.kod = ""; // values.lokalizacja.zip; ten jest undefined
    //   values.miasto = values.lokalizacja.city;
    // } else { // czyszczenie
    //   values.lat = "";
    //   values.lon = "";
    //   values.kod = "";
    //   values.miasto = "";
    // }

    // delete values.lokalizacja;

    // ~ORYGINALNY KOD z GOOGLE API

    values.strona = 1; // dopisanie numeru strony

    // dopisanie sortowania - domyślnie 1 - po dacie
    values.s = 1;

    const convertedUrl = new URLSearchParams(values).toString();  // UWAGA: TRACI SIĘ TYPY

    const path = '/advs/cars/search/' + convertedUrl;
    // przekierowanie parametryczne

    // TEST ---
    // console.log(values);

    history.push({
      pathname: path
    });

    /* powrotna konwersja

    const str = 'abc=foo&def=%5Basf%5D&xyz=5';
    const params = Object.fromEntries(new URLSearchParams(convertedUrl));
    console.log(params);  
    */

  }

  const formik = useFormik({              /* 3 ARGS */
    // 3 args
    initialValues: {
      rodzaj_pojazdu: 1,
      producent: "",
      model: "*", // ( dowolny )
      stan_wyszukaj: 3,
      kolor: "",
      paliwo: "",
      dystans: 10000,
      lokalizacja: null  /* ponieważ przy onSubmit zawsze nadpisuje do wartości przy deklaracji musi być jako pole */
    },
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
    validationSchema: Yup.object({
      // brak walidacji
    }),
  });
  // ~WALIDACJA FORMIK

  // ========== DOMYŚLNE WARTOŚCI FORMULARZA ===================
  // const modelFieldRef = useInnerRef<any>();

  // POLE MODEL ===========================
  const handleModelBlur = () => {

    if (formik.values.model === '')
      formik.setFieldValue('model', '*');

      // formik.setFieldValue('model', '( dowolny )');

  }

  const handleModelFocus = () => {
    // console.log('handle focus');

    if (formik.values.model === '*') {
      formik.setFieldValue('model', '');
      // console.log('warunek czyszczenia pola zaszedł');
    }

    if (debug_mode)
      console.log(formik.values.model);
    // formik.values.model = 'blur';
  }

  // ===========================================================

  useEffect(() => {   // wczytanie
    osmData = undefined; // konieczne czyszczenie np. po kliknięciu wstecz - inaczej bug
    //let car_fuel_types_4search = car_fuel_types;
    //car_fuel_types_4search.unshift({ key: -1, value: -1, text: "( dowolne )" })

    // ustawienie listy marek pojazdów
    setCarBrandsArray(car_brands);   

  }, []);

  const fieldLabelAlign = () : any => {
    if (!sizeMobile)
      return 'center';
    else 
      return 'right';
  }

  const fieldLabelClass = () : any => {

    if (!sizeMobile)
      return 'labelStyle1';
    else 
      return 'labelStyle1NoBold';

  }

  const tabletCol1 = 5;
  const tabletCol2 = 11;

  const computerCol1 = 2; // układ 4 kolumnowy
  const computerCol2 = 6;


  return (
    
    <Container className="containerHMargin" style={{height: '100%'}}>
      <Grid verticalAlign='middle' centered style={{height: '100%'}}>{/* zewnętrzny grid */}
        <Grid.Row>
          <Grid.Column mobile={16} tablet={15} computer={13}> {/* szerokość formularza */}
            {/* tu ewent testy */}
            

            <Header as='h3'>Darmowe ogłoszenia motoryzacyjne - używane auta i nowe. 
<br/>Używane toyota, używane volkswagen i inne. Auto komis internetowy bez opłat za wystawianie ogłoszeń.</Header> 
<br/>
            {/* wnętrze segment-u formy */}
            <FormikProvider value={formik}>

              {/* test */} {/* https://react.semantic-ui.com/modules/dropdown/#types-search-selection */}

              {/* przycisk testowy */}
                {/*
              <FormikButton
                text="Test"
                icon="search"
                name="tst1"
                positive={true}
                disabled={false}
                onClick={()=> { console.log('PRZYCISK TEST i osmData:'); console.log(osmData); }}
              />
                */}
              {/* ~test */}
              
              <Form
                onSubmit={formik.handleSubmit}
                className="attached fluid segment"
                onChange={() => { formik.setStatus(''); }} /* wyczyszczenie komunikatu */
                id = "box" // nadanie stylu do box-a search
              >
                {/* ============== POLA FORMULARZA ============== */}
                {/* wewnętrzny grid pól formy */}
                <Grid fluid="true" className="formPadding" verticalAlign='middle' centered > {/* zagnieżdżony grid pól formy */}

                  {/* POLE RODZAJ && marka POJAZDU */}
                  <Grid.Row>
                    <Grid.Column className={fieldLabelClass()} mobile={16} tablet={tabletCol1} computer={computerCol1} textAlign={fieldLabelAlign()}>
                      rodzaj:
                      </Grid.Column>

                    <Grid.Column className="fieldMargin" mobile={16} tablet={tabletCol2} computer={computerCol2} >
                      <FormikControl
                        control='select'
                        name='rodzaj_pojazdu'
                        options={vehicle_types}
                        value={1}
                      />
                    </Grid.Column>

                  {/* <div className="rowSpacerNoHr"></div> */}

                  {/* POLE marka pojazdu */}

                    <Grid.Column className={fieldLabelClass()} mobile={16} tablet={tabletCol1} computer={computerCol1} textAlign={fieldLabelAlign()}>
                      marka:
                      </Grid.Column>

                    <Grid.Column className="fieldMargin" mobile={16} tablet={tabletCol2} computer={computerCol2}>

                      <FormikControl
                        control='dropDown'
                        name='producent'
                        clearable={true}
                        options={carBrandsArray}
                        // value={-1}  // wybranie domyślnej, to ustawienie wywołuje krzyże clear już przy 1-wszym load
                      />

                      {/* <FormikControl  // poprzednio był select
                        control='select'
                        name='producent'
                        options={carBrandsArray}
                        value={-1}  // wybranie domyślnej
                      /> */}
                    </Grid.Column>
                  </Grid.Row>

                  {/* <div className="rowSpacerNoHr"></div> */}

                  {/* POLE model && stan pojazdu 2,1 */}
                  <Grid.Row>
                    <Grid.Column className={fieldLabelClass()} mobile={16} tablet={tabletCol1} computer={computerCol1} textAlign={fieldLabelAlign()} >
                      model:
                      </Grid.Column>

                    <Grid.Column className="fieldMargin" mobile={16} tablet={tabletCol2} computer={computerCol2}>
                      <FormikControl
                        control='input'
                        name='model'
                        placeholder='...'
                        maxLength="30"
                        attachedHandleBlur={() => { handleModelBlur(); }}
                        onFocus={() => { handleModelFocus(); }}
                        style={{fontFamily: 'serif !important'}}
                      // innerRef={modelFieldRef}
                      // setText={modelFieldText}                    
                      />
                    </Grid.Column>


                  

                  {/* <div className="rowSpacerNoHr"></div> */}

                  {/* POLE stan pojazdu, 2.2 */}
                  
                    <Grid.Column className={fieldLabelClass()} mobile={16} tablet={tabletCol1} computer={computerCol1} textAlign={fieldLabelAlign()} >
                      stan:
                      </Grid.Column>

                    <Grid.Column className="fieldMargin" mobile={16} tablet={tabletCol2} computer={computerCol2}>
                      <FormikControl
                        control='select'
                        name='stan_wyszukaj'
                        options={search_vehicle_states}
                        value={3}
                      />
                    </Grid.Column>
                  </Grid.Row>

                  {/* POLE kolor && paliwo */}

                  <Grid.Row>
                    <Grid.Column className={fieldLabelClass()} mobile={16} tablet={tabletCol1} computer={computerCol1} textAlign={fieldLabelAlign()} >
                      kolor:
                    </Grid.Column>

                    <Grid.Column className="fieldMargin" mobile={16} tablet={tabletCol2} computer={computerCol2}>
                    <FormikControl
                          control='dropDown'
                          name='kolor'          
                          options={vehicle_colors_4search}
                          // value={-1}  // wybranie domyślnej
                        />
                    </Grid.Column>
                  
                   {/* POLE paliwo, 2.2 */}
                  
                    <Grid.Column className={fieldLabelClass()} mobile={16} tablet={tabletCol1} computer={computerCol1} textAlign={fieldLabelAlign()} >
                      paliwo:
                      </Grid.Column>

                    <Grid.Column className="fieldMargin" mobile={16} tablet={tabletCol2} computer={computerCol2}>
                      <FormikControl
                        control='dropDown'
                        name='paliwo'
                        options={car_fuel_types}
                        //options={[{ key: -1, value: -1, text: "*" }, ...car_fuel_types]}  // tutaj unshift nie przechodzi
                        //value={-1}
                      />
                    </Grid.Column>
                  </Grid.Row>

                  {/* ~POLE kolor && paliwo */}

                  {/* POLE miejsce, dystans && szukaj, pojazdu 3,1 */}
                  <Grid.Row>
                    <Grid.Column className={fieldLabelClass()} mobile={16} tablet={tabletCol1} computer={computerCol1} textAlign={fieldLabelAlign()} >
                      miejsce:
                      </Grid.Column>

                    <Grid.Column className="fieldMargin" mobile={16} tablet={tabletCol2} computer={computerCol2}>
                      {/* pole place autocomplete */}
                      {/* pierwotnie dla google AC było to pole input w: </Form.Field> */}
                      
                        <OsmAutoComplete onOsmChange={handleOsmChange}></OsmAutoComplete>
                      
                      {/* ~pole place autocomplete */}
                    </Grid.Column>
                  

                  {/* <div className="rowSpacerNoHr"></div> */}

                  {/* POLE dystans */}
                  
                    <Grid.Column className={fieldLabelClass()} mobile={16} tablet={tabletCol1} computer={computerCol1} textAlign={fieldLabelAlign()} >
                      <span style={isDistDisabled ? { color: '#6e7274', transition: 'none'} : {transition: 'none'}}>dystans:</span>

                      {/* <div style={{width: 80}}>+ km:</div>  na computer jest 3-3*/}
                      </Grid.Column>

                   
                    <Grid.Column className="fieldMargin" mobile={16} tablet={tabletCol2} computer={3}>
                      
                      <div className="distanceSelect"> 
                      <FormikControl
                        disabled = {isDistDisabled}
                        control='select'
                        name='dystans'
                        //options={radius_distance_list}  /* radius_distance_list */
                        options={!isDistDisabled ? radius_distance_list : [{ key: 1, value: 10000, text: "---" }]}  /*  */
                        value={10000} /* 10000 */
                       
                        style={{fontSize: '12px !important'}}
                      />
                      </div> 
                      
                    </Grid.Column>
                  

                    <Grid.Column className="fieldMargin fieldSearchButton" mobile={16} tablet={16} computer={3}>
                      {!sizeMobile && (<div style={{height: 20}}></div>)}
                      {/* */}
                      <FormikButton
                        text="Szukaj"
                        icon="search"
                        name="submit1"
                        type="submit"
                        positive={true}
                        fluid={true}
                        // onClick={()=>{setBlokujDisable(true)}}
                        disabled={false}  // 90px width !formik.isValid && (formik.submitCount >0)
                        className="searchButton" // celem uniknięcia wyświetlania w 2 liniach
                      />
                      {/* style={{ display: 'inline', padding: '12px 7px 12px 7px', marginLeft: 0, marginTop: '25px', marginBottom: '25px' }} */}
                    </Grid.Column>
                  </Grid.Row>

                  {/* ========= ~POLA FORMULARZA ========= */}


                </Grid>
                {/* ~ ======= POLA FORMULARZA ======= */}
              </Form>
            </FormikProvider>


            {/* ~wewnętrzny grid */}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  )
};
export default SearchAdvsForm

/* car brands są na zmiennej stanu, fuel type jest na imporcie */

/* ta strona jedynie generuje url-a który jest przekazywany dalej do SearchAdvsResults */

