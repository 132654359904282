/* źródło : https://www.antstack.com/blog/reusable-form-components-using-react-formik-yup/ */
/* decyzja jaki rodzaj pola zwrócić, bazując na parametrze control */
/* film o FormikControl: https://www.youtube.com/watch?v=4LJ0IXB0Arw */

import React from "react"
import Input from "./Input.js"
import CheckBox from "./CheckBox.js"

import TextArea from "./TextArea.js"
import Select from "./Select.js"
import DropDown from "./DropDown.js"
import RadioButtons from "./RadioButtons.js"
import CheckBoxes from "./CheckBoxes.js"

import RadixCheckBox from "./RadixCheckBox.jsx"


function FormikControl(props) {
  const { control, ...rest } = props

  switch (control) {
    case "input":
      return <Input {...rest} />
    case "checkbox":
      return <CheckBox {...rest} />
    case "radix_checkbox":
      return <RadixCheckBox {...rest} />
    case "textArea":
      return <TextArea {...rest} />
    case "select":
      return <Select {...rest} />
    case "radio":
      return <RadioButtons {...rest} />
    case "checkboxes":
      return <CheckBoxes {...rest} />
    case "dropDown":
      return <DropDown {...rest} />      
    default:
      return null
  }
}
export default FormikControl