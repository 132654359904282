import React from 'react'

const CheckEnv = () => {

  /*
  if (processprocess.env.NODE_ENV === 'development')
    setEnvName('development');

  if (process.env.NODE_ENV === 'production')
    setEnvName('production');
  */
    
  return (
    <div>
      react environment: {process.env.HOST || process.env.HOSTNAME || 'localhost'};
    </div>
  )
}

export default CheckEnv