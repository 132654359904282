/* są 2 scenariusze - id znalezione / nie znalezione
 (ze zdjęciami / bez zdjęć)
*/

import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Button, Container, Grid, Header, Icon } from 'semantic-ui-react'
import { Advs } from '../../api/agent';
import IAdvCarForDisplay from '../../models/advCarModelForDisplay';
import convertAdvCarFromDb from '../../models/convert/advCarConverter'; // przepisanie obiektu

import ImageGallery from 'react-image-gallery';

import ReCAPTCHA from "react-google-recaptcha";

import './ViewAdvCar.css';
import IContactDataForDisplay from '../../models/contactDataForDisplay';
import convertContactData from '../../models/convert/advContactDataConverter';
import IContactDataFromDb from '../../models/contactDataFromDb';

import { useTheme } from "../../common/contexts/ThemeProvider2";

const ViewAdvCar = ({match, history}) => {

  const [adv, setAdv] = useState<IAdvCarForDisplay | null>(null); // no initial value - undefined
  const [advContact, setAdvContact] = useState<IContactDataForDisplay | null>(null); // no initial value - undefined

  const [galleryImages, setGalleryImages] = useState<any>(undefined);

  const [isDataLoading, setIsDataLoading] = useState(true);

  const [loadingRecaptcha, setLoadingRecaptcha] = useState(false); // użyte przy spinnerach przycisków

  const [loadedRecaptcha, setLoadedRecaptcha] = useState(false); // wykorzystane przy przyciskach

 
  // NOWE ZMIENNE

  const recaptchaRef = useRef<any>(); // do odczytu tokena

  const currentTheme = useTheme();  // UWAGA, BEZ {} wczytanie z providera 
  
  const [captchaKeyForReload, setCaptchaKeyForReload] = useState<any>(0); // do reload po zmianie theme

  // reload recaptcha po zmianie theme poprzez zmianę key-a: 
  useEffect(() => {
    let a = captchaKeyForReload + 1;
    setCaptchaKeyForReload(a);
    //console.log(captchaKey);
  }, [currentTheme]); 

  const [displayRecaptcha, setDisplayRecaptcha] = useState(false); // warunkowe wyśweitlanie recaptcha

  const [btnClicked, setBtnClicked] = useState<number | undefined>(undefined); // który przycisk kliknięty

  const pageBottom = useRef<any>();  // do przewijania

  const [contactDataReceived, setContactDataReceived] = useState(false);  // do ukrywania recaptcha, przycisku

  const [reportStatus, setReportStatus] = useState("wysyłanie zgłoszenia ...");
  const [contactStatus, setContactStatus] = useState("pobieranie danych ...");

  const [displayBtnReport, setDisplayBtnReport] = useState(true);
  
  const [displayBtnContact, setDisplayBtnContact] = useState(true);

  // console.log(match.params.id);

  const fetchAdvCar = async (id) => {
    const data = await Advs.getAdvCar(id);
  
    const advConverted = convertAdvCarFromDb(data);

    setIsDataLoading(false);
    setAdv(advConverted);

    let photosArray: any[] = [];

    const photosQty = advConverted.smallPhotos.length;

    for (let i = 0; i < photosQty; i++) {
      const galleryImage = {
        original: advConverted.smallPhotos[i],
        thumbnail: advConverted.bigPhotos[i],
      }

      photosArray.push(galleryImage);
    }
    
    setGalleryImages(photosArray);

}

  useEffect(() => {  // component did mount    
   const id = match.params.id;
    
   fetchAdvCar(id)
    // make sure to catch any error           // tutaj jest lepiej
    .catch((error)=> {          // kwestia undefined jeszcze ..., na 404 mógłby być interceptor
    
      let messageTmp;

      // uwaga: testowanie obecności błędu konieczne jest z ... inaczej undefined
      if (error && error.response && error.response.data && error.response.data.message)  // w przypadku innego błędu ten obiekt będzie undefined
        messageTmp = error.response.data.message;
      else
        messageTmp = "Wystąpił błąd podczas wczytywania ogłoszenia.";

      history.push({
        pathname: '/msg',
        state: {
          type: 'error',
          message: messageTmp  // error.response.data.message
        }
      });

      // ~ obsluga bledu
    });
    // eslint-disable-next-line
  }, []);

    const handleRecaptchaChange = () => {
   
    const token = recaptchaRef.current.getValue();
    const id = match.params.id;

    if (!token || !id || !btnClicked)
      return;

    // WARUNKI KTÓRY PRZYCISK BYŁ KLIKNIĘTY

    if (btnClicked === 1) { // DANE KONTAKTOWE

      setDisplayBtnContact(false); // ukrycie przycisku

      Advs.getAdvCarContactData(id, token)  // token
      .then(function (data: IContactDataFromDb) {
        // 200 ok
        // console.log(data); tylko do debugging

        // USUNIĘCIE CAŁEGO BLOKU RECAPTCHA, przycisku pokaż dane
        setContactDataReceived(true);
        setContactStatus(""); // czyszczenie komunikatu

        // odblokowanie do kolejnego użycia
        setDisplayRecaptcha(false);
        setLoadedRecaptcha(false);

        // wyświetlenie danych
        const daneKontaktowe = convertContactData(data);
        setAdvContact(daneKontaktowe);
      })
      .catch(function (error) {
        // setLoadedRecaptchaMessage("zgłoszenia nie udało się wysłać");
        console.log(error);
        setContactStatus("wystąpił błąd");
      });


    } else if (btnClicked === 2) { // ZGŁOSZENIE

      // OBSŁUGA WYSŁANIA ZGŁOSZENIA

      // ukryć przycisk && wyświetlić tekst wysyłanie zgłoszenia ...
      setDisplayBtnReport(false); // ukrycie przycisku

      Advs.report(id, token)  // token
      .then(function (response: any) {
        // 200 ok
        
        setLoadedRecaptcha(false);
        setDisplayRecaptcha(false);
        setReportStatus("zgłoszenie wysłano");
      })
      .catch(function (error) {
        console.log(error);
        setReportStatus("wystąpił błąd");
      });  

      // ~OBSŁUGA WYSŁANIA ZGŁOSZENIA
    }
  
  }
 

  return (
    <Container>
      {!isDataLoading && (<Header as='h1'>{adv?.producent}, {adv?.model}</Header>)}<br/>
      <Grid fluid="true">

        {isDataLoading ? (
          <div style={{width: '100%', textAlign: 'center'}}>
            <Icon loading name='circle notch' size='big' />
          </div>
        ): (
        <Fragment>
          <Grid.Row>

            <Grid.Column textAlign='center' mobile="16" tablet={12} computer={12} style={{paddingRight: 25}}>
              {/* 1,1 galeria */}
              
              {galleryImages?.length > 0 &&
                <div style={{paddingLeft: 7, paddingBottom: 10}} ><ImageGallery showThumbnails={galleryImages?.length > 1} items={galleryImages} showPlayButton={false}/><br /></div>}

              {galleryImages?.length === 0 &&  
                <img src='../../../assets/noPhoto.jpg' style={{maxHeight: '300px', paddingLeft: 10, paddingBottom: '20px'}} alt="brak zdjęć"/> }
                            
              {/* ~1,1 */}
            </Grid.Column>

            <Grid.Column mobile="16" tablet={4} computer={4}> 
              {/* 1,2 */}
                <div className="topDesc">
                  cena: {adv?.cena}
                  <br/>
                  stan: {adv?.stan}
                  <br/>
                  rocznik: {adv?.rok_produkcji}

                </div>

                <div className="topDesc2" style={{paddingTop: 30, paddingBottom: 30}}>
                  lokalizacja:
                  <br/>
                    {adv?.lokalizacja}
                  <br/>
                  {/* 
                  <br/>
                  ogłoszenie wygasa:
                  <br/>
                    {adv?.dataWygasania}
                  */}
                </div>

                              
              {/* ~1,2 */}
            </Grid.Column>

          </Grid.Row>
          <Grid.Row>
          <img src='../../../assets/section.png' height="21" alt="" className="captionIcon"/><span className="textCaption">&nbsp;OGÓLNE</span><hr className="imageHr"/>
            <div className="txtDesc">
              <div className="txtProp">przebieg: {adv?.przebieg}</div>
              <div className="txtProp">rodzaj paliwa: {adv?.rodzaj_paliwa}</div>
              <div className="txtProp">pojemność silnika: {adv?.pojemnosc_silnika}</div>
              <div className="txtProp">moc silnika: {adv?.moc_silnika} PK</div>
              <div className="txtProp">skrzynia biegów: {adv?.rodzaj_skrzyni}</div>
            </div>
          </Grid.Row>
          <Grid.Row style={{marginTop: '20px'}}>

            <img src='../../../assets/section.png' height="21" alt="" className="captionIcon"/><span className="textCaption">&nbsp;OPIS</span><hr className="imageHr"/>    
            
              <div className="txtEqu">
                <span className="txtEqu">wyposażenie:</span>
                
                {/* przygotowanie poniższego tekstu jest w advCarConverter, wyposazenie */}

                {adv?.opcje_wyposazenia.map((ownAdv, i) => { 
                  return (
                   <span className="txtProp" key={i}>&nbsp;{adv.opcje_wyposazenia[i]};</span>    
                  )
                  })
                }
              </div>
              <div>&nbsp;</div>
              {adv?.opis && (<div className="txtDesc" style={{margin: 25, display: 'block'}}>
                {adv?.opis}
              </div>)}

          </Grid.Row>

          {/* WIERSZ DANE KONTAKTOWE */}
          
          {contactDataReceived && (
            <Grid.Row style={{}}>

              <img src='../../../assets/section.png' height="21" alt="" style={{paddingLeft: 10}} className="captionIcon"/>
              <span className="textCaption">&nbsp;
                DANE KONTAKTOWE
              </span>
              <hr className="imageHr"/>
                
              <div className="txtEqu">
                {advContact?.email && 
                  <Fragment>
                    <img src='../../../assets/email.ico' className='ico' alt=""></img>{advContact?.email}                   
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Fragment>
                }
                {advContact?.numer_tel && 
                  <Fragment>
                    <img src='../../../assets/phone.ico' className='ico' alt=""></img>{advContact?.numer_tel}                   
                  </Fragment>
                }
              </div>  
            
            </Grid.Row>
          )}
          
          {/* WIERSZ RECAPTCHA I PRZYCISKI, 1 WSPÓLNE RECAPTCHA, 2 BYŁYBY DZIWNE */}

          <Grid.Row textAlign='center' style={{marginBottom: '30px', marginTop: '55px'}}>

            {loadedRecaptcha && btnClicked === 1 && (<div style={{width: '100%', textAlign: 'center'}}>
              wymagane potwierdzenie w celu <br/>wyświetlenia danych:<br/>
            </div>) }

            {loadedRecaptcha && btnClicked === 2 && (<div style={{width: '100%', textAlign: 'center'}}>
              wymagane potwierdzenie w celu <br/>wysłania zgłoszenia:<br/>
            </div>) }

            {displayRecaptcha && (<Fragment>              
              
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!}
                onChange={ handleRecaptchaChange }
                asyncScriptOnLoad={()=> { setLoadingRecaptcha(false); setLoadedRecaptcha(true); pageBottom.current?.scrollIntoView({ behavior: 'smooth', inline: "nearest"  });}}
                hl = 'pl'
                style={{margin: '0 auto', marginTop: '15px', marginBottom: '60px'}}
                ref={ recaptchaRef }
                theme= { currentTheme }
                key= { captchaKeyForReload }
              />
 
              </Fragment>
            )}

            {/* PRZYCISKI */}
            <div id='wrapper'>
              <div className='wrapped'>
                {displayBtnContact ? (
                  <Button primary content='Kontakt' size='small' 
                    onClick={()=>{setDisplayRecaptcha(true); setLoadingRecaptcha(true); setBtnClicked(1); }}
                    loading = { !loadedRecaptcha && loadingRecaptcha && btnClicked === 1 }
                  />
                ) : (<span className='btnMessage'>{contactStatus}</span>) }

              </div>
              <div className='wrapped'>
                <Button basic content='< Wstecz' size='small' onClick={history.goBack}></Button>
              </div>
              <div className='wrapped'>
                
                { displayBtnReport ? (
                  <Button basic color='yellow' content='Zgłoś' 
                    size='small' onClick={()=>{setDisplayRecaptcha(true); setLoadingRecaptcha(true); setBtnClicked(2);}}
                    loading = {!loadedRecaptcha && loadingRecaptcha && btnClicked === 2}
                  />
                ) : (
                  <span className='btnMessage'>{reportStatus}</span>
                )}

              </div>
            </div>          

          </Grid.Row>

        </Fragment>
        )}
      </Grid>
      <div ref={pageBottom}>&nbsp;</div>
    </Container>
  )
}

export default ViewAdvCar