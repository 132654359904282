import addIntegerSeparators from "../../common/helpers/AddIntegerSeparators";
import IContactDataFromDb from "../contactDataFromDb";
import IContactDataForDisplay from "../contactDataForDisplay";

const convertContactData = (arg: IContactDataFromDb) : IContactDataForDisplay => {
  // konwersja danych

  let numerTelTmp = '';

  if (arg.numer_tel !== '') // był podany
    numerTelTmp = arg.prefix_tel + ' ' + addIntegerSeparators(arg.numer_tel);

  const result : IContactDataForDisplay = {
    email: arg.email,
    numer_tel: numerTelTmp
  }

  return result;
}

export default convertContactData;