/* SCENARIUSZE, funkcjonalność:
  - walidacja pól required
  - 
  ++ auto complete: change (edit after select), enter pressed

  zewnętrzny grid 1-kolumnowy ustala szerokość całego formularza
    później jest zagnieżdżony grid

    dalej jest jak w form search: są w niej wiersze, każdy po 2 kolumny
*/

import { FormikProvider } from "formik"; // bez form nie łapie submit
import {
  Container,
  Form,
  Grid,
  Message,
  Segment
} from "semantic-ui-react";

import {useEffect, useRef} from 'react';

import { Icon } from "semantic-ui-react";
import FormikControl from "../../common/form-controls/FormikControl";

import { useHistory } from 'react-router-dom';
import React, { Fragment, useState } from 'react';

import FormikButton from "../../common/form-controls/FormikButton";

import useAutoCompleteZip from "../../common/services/autocomplete_zip";

// === import danych ze stałych do list
import car_brands from '../../common/data/select-options/car_brands';
import vehicle_types from '../../common/data/select-options/vehicle_types';
import vehicle_states from '../../common/data/select-options/vehicle_states';
import { vehicle_colors }  from '../../common/data/select-options/vehicle_colors';
import car_fuel_types from '../../common/data/select-options/car_fuel_types';
import car_gearbox_types from '../../common/data/select-options/car_gearbox_types';
import yes_no_list from '../../common/data/select-options/yes_no_list';
import airbags_list from '../../common/data/select-options/airbags_list';
import adv_times_list from '../../common/data/select-options/adv_times_list';
import allowOnlyNumber from '../../common/functions/allowOnlyNubmers';
import contact_types from '../../common/data/select-options/contact_types';

import * as Yup from "yup";
import {useFormik} from "formik"; 
import PhotoUploader from '../cropper/PhotoUploader';
import seller_types from '../../common/data/select-options/seller_types';

import { Advs } from '../../api/agent';

import './CreateAdvForm.css';
import { useScreenSize } from "../../common/contexts/ScreenSizeProvider";
import OsmAutoComplete from "../../common/form-controls/Osm/OsmAutoComplete";
import IOsmACReturnedData from "../../common/form-controls/Osm/OsmAutoCompleteDataModel";

// ============================================================================
                // POCZĄTEK KOMPONENTU //
// ============================================================================
const CreateAdvForm: React.FC<any> = ({ location: { state = {} } }) => {  // muszą być 2x puste obiekty inaczej przy przekierowaniu z PublicRoute bez obiektu location wyrzuci błąd
  
  let debug_mode = false; if (process.env.REACT_APP_IS_PRODUCTION) debug_mode = false;

  const { sizeMobile } = useScreenSize();  // wczytanie z providera obiektu sizeMobile

  const [smallPhotos, setSmallPhotos] = useState<any[]>([]);  // formularz też musi mieć kopię tej tablicy
  const [bigPhotos, setBigPhotos] = useState<any[]>([]);

  const history = useHistory();

  const [capacityLabel, setCapacityLabel] = useState('pojemność');
  const [capacityLabelUnit, setCapacityLabelUnit] = useState('L');

  const [carBrandsArray, setCarBrandsArray] = useState<any[]>([]);

  useEffect(() => {   // wczytanie

  // ustawienie opcji listy car brands
  // car_brands.splice(0, 1);  // usunięcie elementu pierwszego, tj. (dowolne)
  setCarBrandsArray(car_brands);

// ZIP ==========================================
    // async function loadAutocompleteZip() {
    //     // initialize the Google Place Autocomplete widget and bind it to an input element.
    //     // eslint-disable-next-line
    //     autoCompleteZipInited = await autoCompleteZipSvc.init(zipFieldRef.current, handleZipSelect);
    // }

    // loadAutocompleteZip();
// ~ZIP ==========================================    
  }, []);

// ZIP ==========================================
  // const zipFieldRef = useRef<any>();
  // const autoCompleteZipSvc = useAutoCompleteZip();
  // let autoCompleteZipInited = ""; // deklaracja zmiennej na obiekt po init
 
  // const handleZipSelect = async () => {
  //     // console.log('handlezipselect');
  //     let dataObject = await autoCompleteZipSvc.getZipInfo(autoCompleteZipInited);
      
  //     // jeżeli któreś dane nie pełne, np. podano sam kod 42-400 bez miejscowości
  //     // - zwrócić puste pole, do dalszej walidacji YUP
  //     let textValue='';
      
  //     // tylko kiedy pełne dane nadpisanie pola
  //     if (dataObject) { // może być też null, np. przy 22-400
  //         textValue = dataObject.zip + ', ' + dataObject.city;
  //         zipFieldRef.current.value = textValue; // nadpisanie pola tekstowego
  //         formik.setFieldValue('lokalizacja', dataObject);          
  //     } else { 
  //         zipFieldRef.current.value = "";
  //         formik.setFieldValue('lokalizacja', null); 
  //     }
          
  //     // console.log(dataObject);
  // };
// ~ZIP ==========================================

// DYNAMICZNE POLA FORMULARZA ======================================
  
  // INNY PRODUCENT
  const [show_producent_inny, setShow_producent_inny] = useState(false);

  const producent_select_changed = (value) => {

    // formik.setFieldTouched('producent', true);

   // formik.validateField('producent');

    // formik.handleChange(value, 'producent');   // chyba nie ma tego

    if (value === 87) { // inny: wyświetlenie pola, ewent. rezygnacja z hard coded: przeszukiwanie
      setShow_producent_inny(true);
      formik.setFieldValue('isInnyProducentDisplayed', true); // włączenie walidacji 
    } else { // ukrycie pola
      setShow_producent_inny(false);
      formik.setFieldValue('isInnyProducentDisplayed', false); //  
    }

  }

  // NUMER TELEFONU
  const [displayPhoneField, setDisplayPhoneField] = useState(false);

  const kontakt_select_changed = (value) => {

    // jeśli 2 albo 3 - display phone - else /hide

    if (value === 2 || value === 3) { // wyświetlenie 2 pól telefonu i włączenie walidacji
      setDisplayPhoneField(true);
      formik.setFieldValue('isPrefixTelefonuDisplayed', true); // włączenie walidacji
      formik.setFieldValue('isTelefonDisplayed', true); // włączenie walidacji
    } else { // ukrycie pól i wyłączenie walidacji
      setDisplayPhoneField(false);
      formik.setFieldValue('isPrefixTelefonuDisplayed', false);
      formik.setFieldValue('isTelefonDisplayed', false); 
    }

  }

  // INNY KOLOR

  const [show_color_inny, setShow_color_inny] = useState(false);
  
  const kolor_select_changed = (value) => {

    // formik.setFieldError('kolor', undefined);  // ?wartość
    // formik.validateField('kolor');

    // formik.setFieldError('kolor', undefined);  // żeby usunąć podświetlenie czerwone po wyborze wartości
    
    if (value === 10) {  // pokazać pole
      setShow_color_inny(true);
      formik.setFieldValue('isInnyKolorDisplayed', true); // włączenie walidacji
      // setFocus //
    } else { // ukryć pole
      setShow_color_inny(false);
      formik.setFieldValue('isInnyKolorDisplayed', false);
    }
  }

// ~ DYNAMICZNE POLA FORMULARZA =====================================



const capitalizeEachWord = (arg: string) => {
  
  const argLower = arg.toLowerCase();

  //split the above string into an array of strings 
  //whenever a blank space is encountered

  const arr = argLower.split(" ");

  //loop through each element of the array and capitalize the first letter.

  for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }

  //Join all the elements of the array back into a string 
  //using a blankspace as a separator 
  const result = arr.join(" ");
  
  return result;
}

// === WALIDACJA FORMIK =============================================

const handleFormSubmit = async (values) => {
  // console.log('doszlo do wywolania handleFormSubmit');

  // === PRZYGOTOWANIE OBIEKTU Z DANYMI LOKALIZACYJNYMI:  

  // === wymuszenie rodzaju danej:

  // @ts-ignore  "possibly undefined" - nie będzie undefined bo była reguła walidacji formik-a
  const osmData: IOsmACReturnedData = formik.values.osmACData;  // wymuszenie rodzaju danych w celu uniknięcia typo

  // nie wolno zerować kodu na -2 bo nie przejdzie zapis wg. modelu dokumentu mongo
  let zipTmp: string = (osmData.zip === "-2") ? osmData.province : osmData.zip  // być może województwo

  // ewnetualnie poprawić kod:
  const lokalizacjaTmp = {  // wysyłany obiekt, wg. api ma mieć city, zip, lat, lng
    city: osmData.name,
    zip: zipTmp, // podmiana na województwo dla -2
    lat: osmData.lat,
    lng: osmData.lon
  }

  // WYSŁANIE REQUESTA POST Z DANYMI
   
  // dopisanie talbic z obrazkami do wysyłanego obiektu

  const modelTmp = values.model.toLowerCase();
  
  delete values.model;

  const modelTmpUpper = capitalizeEachWord(modelTmp);

  const formDataObject = {
    ...values,
    smallPhotos: smallPhotos,
    bigPhotos: bigPhotos,
    model: modelTmpUpper,  // zamiana na uppercase
    lokalizacja: lokalizacjaTmp
  } 
  
  // delete formDataObject.acInput;
  delete formDataObject.osmACData;
  delete formDataObject.isPrefixTelefonuDisplayed;
  delete formDataObject.isTelefonDisplayed;

  delete formDataObject.isInnyKolorDisplayed;
  delete formDataObject.isInnyProducentDisplayed;

  /* telefon i tak jest w opcje kontaktu, a kolor w polu kolorInny, */

  /* konieczna jest jeszcze konwersja, np. ceny do sortowania */

  if (debug_mode)
  {
    console.log('Dane OK, wysłanie requesta z obiektem:');
    console.log(formDataObject); 
  }
 

  // CHWILOWO NIE WYSŁANIE REQEUST-A:   return;

  // WYSŁANIE REQUEST-A POST
  try {
    // console.log(formDataObject); return;
    await Advs.saveAdv(formDataObject);
    // console.log('zapisano');        
    
    history.push({
      pathname: '/msg',
      state: {
        type: 'success',
        message: 'Ogłoszenie zostało pomyślnie dodane. Zarządanie nim jest możliwe w sekcji moje ogłoszenia.'
      }
    });

  } catch (error: any) {  // to działało
    console.log(error);

    history.push({
      pathname: '/msg',
      state: {
        type: 'error',
        message: error.response.data.message
      }
    });

  }

}

/* niestety przeniesienie obiektu formik do odrębnego pliku wiąże się 
z problemami, gdyż potrzebuje dostępu do submit, a z kolei kiedy
w zewnętrznym pliku jest też submit, to nie dostępu do obiektu z danymi lokalizacyjnymi
*/


const formik = useFormik({              /* 3 ARGS */
  // 3 args
  initialValues: {
    rodzaj_pojazdu: 1,
    producent: "",
    producent_inny: "",
    model: "",
    stan: "",
    // lokalizacja wybrana z listy  : //* obiekt */
    // acInput: "",
    osmACData: undefined, /* jako obiekt */
    cena: "",
    rok_produkcji: "",
    przebieg: "",
    kolor: "",
    kolor_inny: "",
    // [zjęcia]     :
    rodzaj_paliwa: "",
    pojemnosc_silnika: "",
    moc_silnika: "",
    rodzaj_skrzyni: "",
    poduszkiQty: "",
    abs: "",
    klima: "",
    immo: "",
    centralka: "",
    gps: "",
    radio: "",
    czujniki_parkowania: "",
    el_szyby: "",
    el_lusterka: "",
    opis: "",
    tygodnieQty: "",
    rodzaj_sprzedawcy: "",
    opcje_kontaktu: "",
    prefix_tel: "+48",
    numer_tel: "",
    // pola warunkowo wyświetlane:
    isInnyKolorDisplayed: false, 
    isInnyProducentDisplayed: false,
    isPrefixTelefonuDisplayed: false,
    isTelefonDisplayed: false,
    // form_error: false    /* komunikat zbiorczy */
    lokalizacja: null  /* ponieważ przy onSubmit zawsze nadpisuje do wartości przy deklaracji musi być jako pole */
  },
  onSubmit: (values) => {
    handleFormSubmit(values);
  },
  validationSchema: Yup.object({
    // locationData: Yup.object().shape({}).noUnknown(),
    rodzaj_pojazdu: Yup.number().required(''),
    producent: Yup.number().required('').notOneOf([-1, -2, -3], ''),  //

    // dynamiczne pole
    producent_inny: Yup.string().when('isInnyProducentDisplayed', {
      is: true,
      then: (schema) => schema.required(''),
      otherwise: (schema) => schema.notRequired()
    }),
    // ~dynamiczne pole    

    model: Yup.string().required(''),
    stan: Yup.number().required(''),

    // lokalizacja wybrana z listy  : //* obiekt */
    //acInput: Yup.string().required('err'),
    osmACData: Yup.object().notOneOf([undefined], 'błąd lokalizacji'), // '' jak jest pusto to war && out - nie wykrywa błędu

    cena: Yup.number().required(''),
    rok_produkcji: Yup.string().required(''),
    przebieg: Yup.string().required(''),
    kolor: Yup.number().required(''),

    // dynamiczne pole
    kolor_inny: Yup.string().when('isInnyKolorDisplayed', {
      is: true,
      then: (schema) => schema.required(''),
      otherwise: (schema) => schema.notRequired()
    }),
    // ~dynamiczne pole       

    // ======================
    // zdjęcia

    rodzaj_paliwa: Yup.number().required(''),
    pojemnosc_silnika: Yup.string().required('').matches(/^(\d+(\.{1}\d+)?)$/, ' '),
    moc_silnika: Yup.string().required(''),
    rodzaj_skrzyni: Yup.number().required(''),
    poduszkiQty: Yup.number().required(''),
    abs: Yup.boolean().required(''),
    klima: Yup.boolean().required(''),
    immo: Yup.boolean().required(''),
    centralka: Yup.boolean().required(''),
    gps: Yup.boolean().required(''),
    radio: Yup.boolean().required(''),
    czujniki_parkowania: Yup.boolean().required(''),
    el_szyby: Yup.boolean().required(''),
    el_lusterka: Yup.boolean().required(''),
    tygodnieQty: Yup.number().required(''),
    rodzaj_sprzedawcy: Yup.number().required(''),
    opcje_kontaktu: Yup.number().required(''),

    // dynamiczne pola nr telefonu
    prefix_tel: Yup.string().when('isPrefixTelefonuDisplayed', {
      is: true,
      then: (schema) => schema.required(''),
      otherwise: (schema) => schema.notRequired()
    }),

    numer_tel: Yup.string().when('isTelefonDisplayed', {
      is: true,
      then: (schema) => schema.required(''),
      otherwise: (schema) => schema.notRequired()
    })  
    // ~dynamiczne pola

    // NUMER TELEFONU

  }),
});
// ~WALIDACJA FORMIK

    // dynamiczne etykiety L / kWH przy pojemność

    const rodzajPaliwa_select_changed = (arg) => {
      // console.log(arg);

      if (arg === 7) { // prąd elektryczny
        setCapacityLabel("pojemność akumulatora");
        setCapacityLabelUnit("kWH");
      } else {
        setCapacityLabel("pojemność");
        setCapacityLabelUnit("L");
      }
    }
    // ~ dynamiczne etykiety L / kWH przy pojemność

    const handleOsmChange = (arg) => {  // wiele zdarzeń undefined, przy każdym naciśnięciu klawisza
      
      if (debug_mode)
      {
        console.log('zdarzenie handleOsmChange,  przechwycone w Create Adv Form, arg:')
        console.log(arg);
      }
      
      formik.setFieldValue('osmACData', arg);
      
    }; // ma zachodzić kiedy wybrano / wyczyszczono dane


    const handleOsmBlur = () => {  // wiele zdarzeń undefined, przy każdym naciśnięciu klawisza
      
      if (debug_mode)
        console.log('zdarzenie blur przechwycone w Create Adv Form');
      
      formik.setFieldTouched('osmACData', true);
    }; // ma zachodzić kiedy wybrano / wyczyszczono dane

    // const acInput_changed = (value) => {
    //   formik.setFieldValue('acInput', value);
    //   formik.setFieldValue('lokalizacja', null); 
    // }

    const resFieldLabel = () => {
      if (sizeMobile) {
        return { textAlign: 'right' }
      } else {
        return { textAlign: 'center' } 
      }
    }

    const col1_tablet=6;
    const col2_tablet=10;

    const col1_computer=6;
    const col2_computer=10;
 
  return (     
      <Container style={{marginTop: 50, marginBottom: 50}}>
        <Grid centered>{/* zewnętrzny grid */}
          <Grid.Row>          
            <Grid.Column mobile={16} tablet={15} computer={12}> {/* szerokość formularza */}           
              <Segment clearing>
                <Message attached header='NOWE OGŁOSZENIE, AUTA' /> {/* ewent. tu dać form */}
                {/* wnętrze segment-u formy */}
                <FormikProvider value={formik}>
                  <Form
                    onSubmit={formik.handleSubmit}
                    className="attached fluid segment"
                    onChange={() => { formik.setStatus(''); }} /* wyczyszczenie komunikatu */
                  >
        {/* ============== POLA FORMULARZA ============== */}
                    {/* wewnętrzny grid pól formy */}
                    <Grid fluid="true" centered>{/* zagnieżdżony grid pól formy */}
        
                      {/* NAGŁÓWEK SEKCJI */}
                      <Grid.Row centered width="16" className="group-caption-background">
                        <div className="group-caption-text">OGÓLNE<br/></div>
                      </Grid.Row>

                      {/* POLE RODZAJ POJAZDU */}
                      <Grid.Row>
                        <Grid.Column className="labelStyle1" verticalAlign='middle' style={resFieldLabel()} mobile={16} tablet={col1_tablet} computer={col1_computer}>
                          rodzaj:                 
                        </Grid.Column>

                        <Grid.Column mobile={16} tablet={col2_tablet} computer={col2_computer}>
                          <FormikControl
                            control='select'
                            name='rodzaj_pojazdu'
                            options={vehicle_types}
                            value={1}
                            disabled={true}
                            // value={formik.values[rodzaj_pojazdu]} 
                          /> 
                        </Grid.Column>          
                      </Grid.Row>
                      
                      <div className="lineSpacer"></div>

                      {/* POLE MARKA */}
                      <Grid.Row>
                        <Grid.Column className="labelStyle1" verticalAlign='middle' style={resFieldLabel()} mobile={16} tablet={col1_tablet} computer={col1_computer}>
                          marka:
                        </Grid.Column>

                        <Grid.Column mobile={16} tablet={col2_tablet} computer={col2_computer}>
                          <FormikControl
                            control='select'
                            name='producent'                          
                            options={carBrandsArray}   // musi być początkowo pusta tablica, lub wartość funkcji, stąd nawiasy
                            changeCallback={(arg) => {producent_select_changed(arg)}}                         
                          />
                        </Grid.Column>
                      </Grid.Row>

                      <div className="lineSpacer"></div>

                      {/* POLE INNA MARKA */}
                      {show_producent_inny && (<Fragment><Grid.Row>
                        <Grid.Column verticalAlign='middle' style={resFieldLabel()} mobile={16} tablet={col1_tablet} computer={col1_computer}>

                        </Grid.Column>

                        <Grid.Column mobile={16} tablet={col2_tablet} computer={col2_computer}>
                          <FormikControl
                            control='input'
                            name='producent_inny'
                            placeholder='nazwa innej marki'
                            maxLength="30"
                          />
                        </Grid.Column>
                      </Grid.Row><div className="lineSpacer"></div></Fragment>)}                    

                      {/* POLE MODEL */}
                      <Grid.Row>
                        <Grid.Column className="labelStyle1" verticalAlign='middle' style={resFieldLabel()} mobile={16} tablet={col1_tablet} computer={col1_computer}>
                          model:
                        </Grid.Column>

                        <Grid.Column mobile={16} tablet={col2_tablet} computer={col2_computer}>
                          <FormikControl 
                            control='input'
                            name='model'
                            placeholder='...'
                            maxLength="30"
                          />    
                        </Grid.Column>
                      </Grid.Row>

                      <div className="lineSpacer"></div>

                      {/* POLE STAN */}
                      <Grid.Row>
                        <Grid.Column className="labelStyle1" verticalAlign='middle' style={resFieldLabel()} mobile={16} tablet={col1_tablet} computer={col1_computer}>
                          stan:
                        </Grid.Column>

                        <Grid.Column mobile={16} tablet={col2_tablet} computer={col2_computer}>
                          <FormikControl
                            control='select'
                            name='stan'                          
                            options={vehicle_states}
                          />
                        </Grid.Column>
                      </Grid.Row>

                      <div className="lineSpacer"></div>

                      {/* POLE LOKALIZACJA AUTOCOMPLETE */}
                      <Grid.Row>
                        <Grid.Column className="labelStyle1" verticalAlign='middle' style={resFieldLabel()} mobile={16} tablet={col1_tablet} computer={col1_computer}>
                          lokalizacja<br/><u>z listy:</u>   
                        </Grid.Column>

                        <Grid.Column mobile={16} tablet={col2_tablet} computer={col2_computer}>
                          <Form.Field error={formik.errors.osmACData && formik.touched.osmACData}> {/* {formik.errors.acInput && formik.touched.acInput}> */}
                           {/* {JSON.stringify(formik.errors.osmACData)} */}

                          <OsmAutoComplete 
                            onOsmChange={handleOsmChange} onOsmBlur={handleOsmBlur}></OsmAutoComplete>
                            
                            
                            {/* STARY KOD:                            
                              <input
                                id="acInput"
                                name="acInput"
                                type="text"
                                className="form-field" 
                                placeholder="kod pocztowy, miejscowość"
                                ref={zipFieldRef} // dataObjectZip = null;
                                onChange={(e) => acInput_changed(e.target.value)} // chroni przed edycją po wyborze, czyści współrzędne 
                                onBlur={() => {formik.setFieldTouched('acInput')}}
                                // onChange={formik.handleChange}  // nie działa 
                              />             
                            */}
                          </Form.Field>
                        </Grid.Column>
                      </Grid.Row>

                      <div className="lineSpacer"></div>

                      {/* POLE CENA ZŁOTYCH _ INT */}
                      <Grid.Row>
                        <Grid.Column className="labelStyle1" verticalAlign='middle' style={resFieldLabel()} mobile={16} tablet={col1_tablet} computer={col1_computer}>
                          cena:
                        </Grid.Column>

                      <Grid.Column mobile={16} tablet={col2_tablet} computer={col2_computer}>
                        <FormikControl
                          control='input'
                          name='cena'
                          placeholder='...'
                          appendedLabel='zł'
                          onKeyDown={(e) => {allowOnlyNumber(e, false)}}
                          maxLength="7"
                          isInt={true}
                        />  
                      </Grid.Column>
                    </Grid.Row>

                    <div className="lineSpacer"></div>

                    {/* POLE ROK PRODUKCJI, INT */}
                    <Grid.Row>
                      <Grid.Column className="labelStyle1" verticalAlign='middle' style={resFieldLabel()} mobile={16} tablet={col1_tablet} computer={col1_computer}>
                        rok produkcji:
                      </Grid.Column>

                      <Grid.Column mobile={16} tablet={col2_tablet} computer={col2_computer}>
                        <FormikControl 
                          control='input'
                          name='rok_produkcji'
                          onKeyDown={(e) => {allowOnlyNumber(e, false)}}
                          maxLength='4'
                          isInt={true}
                        />    
                      </Grid.Column>
                    </Grid.Row>

                    <div className="lineSpacer"></div>

                    {/* POLE PRZEBIEG _ KM, INT - */}
                    <Grid.Row>
                      <Grid.Column className="labelStyle1" verticalAlign='middle' style={resFieldLabel()} mobile={16} tablet={col1_tablet} computer={col1_computer}>
                        przebieg:
                      </Grid.Column>

                      <Grid.Column mobile={16} tablet={col2_tablet} computer={col2_computer}>
                        <FormikControl 
                          control='input'
                          name='przebieg'
                          placeholder='...'
                          appendedLabel='km'
                          onKeyDown={(e) => {allowOnlyNumber(e, false)}}
                          maxLength='7'
                          isInt={true}
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <div className="lineSpacer"></div>

                    {/* POLE KOLOR */}
                    <Grid.Row>
                      <Grid.Column className="labelStyle1" verticalAlign='middle' style={resFieldLabel()} mobile={16} tablet={col1_tablet} computer={col1_computer}>
                        kolor:
                      </Grid.Column>

                      <Grid.Column mobile={16} tablet={col2_tablet} computer={col2_computer}>
                        <FormikControl
                          control='select'
                          name='kolor'                          
                          options={vehicle_colors}
                          changeCallback={(arg) => {kolor_select_changed(arg)}}
                        /> {/* onChange={(e, date) => {color_select_changed(date.value)}} */}              
                      </Grid.Column>
                    </Grid.Row>

                    <div className="lineSpacer"></div>       

                    {/* POLE KOLOR_INNY */}
                    {show_color_inny && (<Fragment><Grid.Row>
                      <Grid.Column verticalAlign='middle' style={resFieldLabel()} mobile={16} tablet={col1_tablet} computer={col1_computer}>                              
                      </Grid.Column>

                      <Grid.Column mobile={16} tablet={col2_tablet} computer={col2_computer}>
                        <FormikControl style={{paddingTop: '5px'}}
                          control='input'
                          name='kolor_inny'
                          placeholder='nazwa innego koloru'
                          maxLength="30"
                        />        
                      </Grid.Column>
                    </Grid.Row>

                      <div className="lineSpacer"></div></Fragment> )}

                    {/* POLE ZDJĘCIA */}
                    <Grid.Row style={{marginTop: 48, marginBottom: 0}}>
                      <Grid.Column verticalAlign='middle' width={16}>
                      <PhotoUploader setSmallPhotosOut={setSmallPhotos} setBigPhotosOut={setBigPhotos} limit={9}/>
                      </Grid.Column>
                    </Grid.Row>

                    {sizeMobile && (<div style={{height: 15}}></div> )}

                    {/* == :SPECYFIKACJA SILNIKA: == */}
                    <Grid.Row centered width="16" className="group-caption-background">
                      <div className="group-caption-text">SPECYFIKACJA SILNIKA<br/></div>
                    </Grid.Row>

                    {/* POLE RODZAJ PALIWA, uwaga: następne pole: w L lub kWH - */}
                    <Grid.Row>
                      <Grid.Column className="labelStyle1" verticalAlign='middle' style={resFieldLabel()} mobile={16} tablet={col1_tablet} computer={col1_computer}>
                        rodzaj paliwa:
                      </Grid.Column>

                      <Grid.Column mobile={16} tablet={col2_tablet} computer={col2_computer}>
                        <FormikControl
                          control='select'
                          name='rodzaj_paliwa'
                          options={car_fuel_types}
                          changeCallback={(arg) => {rodzajPaliwa_select_changed(arg)}} 
                        />
                      </Grid.Column>
                    </Grid.Row>

                      <div className="lineSpacer"></div>

                    {/* POLE POJEMNOŚĆ, uwaga: w L lub kWH - */}
                    <Grid.Row>
                      <Grid.Column className="labelStyle1" verticalAlign='middle' style={resFieldLabel()} mobile={16} tablet={col1_tablet} computer={col1_computer}>
                        {capacityLabel}:
                      </Grid.Column>

                      <Grid.Column mobile={16} tablet={col2_tablet} computer={col2_computer}>
                        <FormikControl 
                          control='input'
                          name='pojemnosc_silnika'
                          placeholder='...'
                          appendedLabel={capacityLabelUnit}
                          onKeyDown={(e) => {allowOnlyNumber(e, true, false)}}
                          maxLength='4'
                          isFloat={true}
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <div className="lineSpacer"></div>

                    {/* POLE MOC */}
                    <Grid.Row>
                      <Grid.Column className="labelStyle1" verticalAlign='middle' style={resFieldLabel()} mobile={16} tablet={col1_tablet} computer={col1_computer}>
                        moc:
                      </Grid.Column>

                      <Grid.Column mobile={16} tablet={col2_tablet} computer={col2_computer}>
                        <FormikControl 
                          control='input'
                          name='moc_silnika'
                          placeholder='...'
                          appendedLabel='PK'
                          onKeyDown={(e) => {allowOnlyNumber(e, false)}}
                          maxLength='4'
                          isInt={true}
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <div className="lineSpacer"></div>

                    {/* POLE SKRZYNIA BIEGÓW */}
                    <Grid.Row>
                      <Grid.Column className="labelStyle1" verticalAlign='middle' style={resFieldLabel()} mobile={16} tablet={col1_tablet} computer={col1_computer}>
                        skrzynia biegów:
                      </Grid.Column>

                      <Grid.Column mobile={16} tablet={col2_tablet} computer={col2_computer}>
                        <FormikControl
                          control='select'
                          name='rodzaj_skrzyni'                          
                          options={car_gearbox_types}
                        />
                      </Grid.Column>
                    </Grid.Row>

                    {/* == :WYPOSAŻENIE: == */}
                    <Grid.Row centered width="16" className="group-caption-background">
                      <div className="group-caption-text">WYPOSAŻENIE<br/></div>
                    </Grid.Row>

                    {/* POLE PODUSZKI POWIETRZNE */}
                    <Grid.Row>
                      <Grid.Column className="labelStyle1" verticalAlign='middle' style={resFieldLabel()} mobile={16} tablet={col1_tablet} computer={col1_computer}>
                        poduszki powietrzne:
                      </Grid.Column>

                      <Grid.Column mobile={16} tablet={col2_tablet} computer={col2_computer}>
                        <FormikControl
                          control='select'
                          name='poduszkiQty'                          
                          options={airbags_list}
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <div className="lineSpacer"></div>                     

                    {/* POLE ABS */}
                    <Grid.Row>
                      <Grid.Column className="labelStyle1" verticalAlign='middle' style={resFieldLabel()} mobile={16} tablet={col1_tablet} computer={col1_computer}>
                        ABS:
                      </Grid.Column>
                        
                      <Grid.Column mobile={16} tablet={col2_tablet} computer={col2_computer}>
                        <FormikControl
                          control='select'
                          name='abs'                          
                          options={yes_no_list}
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <div className="lineSpacer"></div>        

                    {/* POLE KLIMATYZACJA */}
                    <Grid.Row>
                      <Grid.Column className="labelStyle1" verticalAlign='middle' style={resFieldLabel()} mobile={16} tablet={col1_tablet} computer={col1_computer}>
                        klimatyzacja:
                      </Grid.Column>            

                      <Grid.Column mobile={16} tablet={col2_tablet} computer={col2_computer}>
                        <FormikControl
                            control='select'
                            name='klima'                          
                            options={yes_no_list}
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <div className="lineSpacer"></div>        

                    {/* POLE IMMOBILIZER */}
                    <Grid.Row>
                      <Grid.Column className="labelStyle1" verticalAlign='middle' style={resFieldLabel()} mobile={16} tablet={col1_tablet} computer={col1_computer}>
                        immobilizer:
                      </Grid.Column>            
                      <Grid.Column mobile={16} tablet={col2_tablet} computer={col2_computer}>
                        <FormikControl
                          control='select'
                          name='immo'                          
                          options={yes_no_list}
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <div className="lineSpacer"></div>        

                    {/* POLE CENTRALKA */}
                    <Grid.Row>
                      <Grid.Column className="labelStyle1" verticalAlign='middle' style={resFieldLabel()} mobile={16} tablet={col1_tablet} computer={col1_computer}>
                        centralny zamek:
                      </Grid.Column>

                      <Grid.Column mobile={16} tablet={col2_tablet} computer={col2_computer}>
                        <FormikControl
                          control='select'
                          name='centralka'
                          options={yes_no_list}
                        />             
                      </Grid.Column>
                    </Grid.Row>

                    <div className="lineSpacer"></div>                      

                    {/* POLE GPS */}
                    <Grid.Row>
                      <Grid.Column className="labelStyle1" verticalAlign='middle' style={resFieldLabel()} mobile={16} tablet={col1_tablet} computer={col1_computer}>
                        nawigacja GPS:
                      </Grid.Column>            
                      <Grid.Column mobile={16} tablet={col2_tablet} computer={col2_computer}>
                        <FormikControl
                          control='select'
                          name='gps'
                          options={yes_no_list}
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <div className="lineSpacer"></div>        

                    {/* POLE RADIO */}
                    <Grid.Row>
                      <Grid.Column className="labelStyle1" verticalAlign='middle' style={resFieldLabel()} mobile={16} tablet={col1_tablet} computer={col1_computer}>
                        radio:
                      </Grid.Column>

                      <Grid.Column mobile={16} tablet={col2_tablet} computer={col2_computer}>
                        <FormikControl
                          control='select'
                          name='radio'
                          options={yes_no_list}
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <div className="lineSpacer"></div>        

                    {/* POLE CZUJNIKI PARKOWANIA */}
                    <Grid.Row>
                      <Grid.Column className="labelStyle1" verticalAlign='middle' style={resFieldLabel()} mobile={16} tablet={col1_tablet} computer={col1_computer}>
                        czujniki parkowania:
                      </Grid.Column>

                      <Grid.Column mobile={16} tablet={col2_tablet} computer={col2_computer}>
                        <FormikControl
                          control='select'
                          name='czujniki_parkowania'
                          options={yes_no_list}
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <div className="lineSpacer"></div>        

                    {/* POLE ELEKTRYCZNE SZYBY */}
                    <Grid.Row>
                      <Grid.Column className="labelStyle1" verticalAlign='middle' style={resFieldLabel()} mobile={16} tablet={col1_tablet} computer={col1_computer}>
                        elektryczne szyby:
                      </Grid.Column>

                      <Grid.Column mobile={16} tablet={col2_tablet} computer={col2_computer}>
                        <FormikControl
                          control='select'
                          name='el_szyby'
                          options={yes_no_list}
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <div className="lineSpacer"></div>

                    {/* POLE ELEKTRYCZNE LUSTERKA */}
                    <Grid.Row>
                      <Grid.Column className="labelStyle1" verticalAlign='middle' style={resFieldLabel()} mobile={16} tablet={col1_tablet} computer={col1_computer}>
                        elektryczne lusterka:
                      </Grid.Column>

                      <Grid.Column mobile={16} tablet={col2_tablet} computer={col2_computer}>
                        <FormikControl
                          control='select'
                          name='el_lusterka'
                          options={yes_no_list}
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <div className="lineSpacer"></div>                                   

                    {/* POLE _opcja opis: textarea */}
                    <Grid.Row>
                      <Grid.Column className="labelStyle1" verticalAlign='middle' style={resFieldLabel()} mobile={16} tablet={col1_tablet} computer={col1_computer}>
                        <span style={{color: 'green', fontWeight: 'bold'}}>*</span>opis:
                      </Grid.Column>

                      <Grid.Column mobile={16} tablet={col2_tablet} computer={col2_computer}>
                        <Form.TextArea
                          placeholder='...'
                          maxLength='1500'
                          name='opis'
                          rows='7'
                          onChange={(e) => {formik.setFieldValue('opis', e.target.value)}}
                        />
                      </Grid.Column>
                    </Grid.Row>

                    {/* == :OPCJE OGŁOSZENIA: == */}
                    <Grid.Row centered width="16" className="group-caption-background">
                      <div className="group-caption-text">OPCJE OGŁOSZENIA<br/></div>
                    </Grid.Row>

                    {/* POLE - czas wystawienia: 1 - */}
                    <Grid.Row>
                      <Grid.Column className="labelStyle1" verticalAlign='middle' style={resFieldLabel()} mobile={16} tablet={col1_tablet} computer={col1_computer}>
                        czas wystawienia:
                      </Grid.Column>

                      <Grid.Column mobile={16} tablet={col2_tablet} computer={col2_computer}>
                        <FormikControl
                          control='select'
                          name='tygodnieQty'                          
                          options={adv_times_list}
                        />            
                      </Grid.Column>
                    </Grid.Row>

                    <div className="lineSpacer"></div>

                    {/* POLE RODZAJ SPRZEDAWCY */}
                    <Grid.Row>
                      <Grid.Column className="labelStyle1" verticalAlign='middle' style={resFieldLabel()} mobile={16} tablet={col1_tablet} computer={col1_computer}>
                        rodzaj sprzedawcy:
                      </Grid.Column>

                      <Grid.Column mobile={16} tablet={col2_tablet} computer={col2_computer}>
                        <FormikControl
                          control='select'
                          name='rodzaj_sprzedawcy'                        
                          options={seller_types}
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <div className="lineSpacer"></div>        

                    {/* POLE opcja: telefon kontaktowy */}
                    <Grid.Row>
                      <Grid.Column className="labelStyle1" verticalAlign='middle' style={resFieldLabel()} mobile={16} tablet={col1_tablet} computer={col1_computer}>
                        kontakt:
                      </Grid.Column>

                      <Grid.Column mobile={16} tablet={col2_tablet} computer={col2_computer}>
                        <FormikControl
                          control='select'
                          name='opcje_kontaktu'                          
                          options={contact_types}    
                          changeCallback={(arg) => {kontakt_select_changed(arg)}}                        
                        />
                        {displayPhoneField && (
                        <Fragment>
                          <br/>                        
                          <Form.Group>                    
                            <FormikControl 
                              control='input'
                              name='prefix_tel'
                              // defaultValue="+48"
                              onKeyDown={(e) => {allowOnlyNumber(e, false, true)}}
                              maxLength='4'
                              style={{width: '63px'}}
                            />
                            <FormikControl 
                              control='input'
                              name='numer_tel'
                              placeholder='...'
                              onKeyDown={(e) => {allowOnlyNumber(e, false)} }
                              maxLength='9'
                              fluid={true}
                              width={16}
                            />
                          </Form.Group>
                        </Fragment>
                        )}   
                      </Grid.Column>
                    </Grid.Row>

                    <div className="lineSpacer" style={{borderColor: 'transparent'}}></div>

                    {/* POLE - PRZYCISK ZAPISZ - */}
                    <Grid.Row style={{marginBottom: 5}}>
                      <Grid.Column verticalAlign='middle' width={16}>
                        <FormikButton
                          text="Dodaj ogłoszenie"
                          icon="save"
                          name="submit1"
                          type="submit"
                          positive={true}
                          fluid={true}
                          // onClick={()=>{setBlokujDisable(true)}}
                          disabled={false}  // !formik.isValid && (formik.submitCount >0)
                        /> {/* disabled={!(formik.isValid && formik.dirty) || !!(formik.status) || formik.isSubmitting} */}
                      </Grid.Column>
                    </Grid.Row> 

                    <div className="lineSpacer" style={{borderColor: 'transparent'}}></div>
        
                    </Grid>{/* ~zagnieżdżony grid pól formy */}
        {/* ~ ======= POLA FORMULARZA ======= */}
                  </Form>
                </FormikProvider>
                {/* ~wnętrze segment-u formy */}
                {!formik.isValid && (formik.submitCount > 0) && (
                  <Fragment>
                    <Message
                      attached="bottom"
                      error style={{ textAlign: "centered" }}>
                      <Icon name="exclamation" />
                        Zaznaczone pola wymagają poprawy
                    </Message>
                  </Fragment>
                )}
              </Segment>
              {/* ~wewnętrzny grid */}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
  )
};

export default CreateAdvForm;

// =============================================
// stary kod - dane lokalizacyjne:
  // console.log(formik.values.locationData);

  // sprawdzenie zawartości obiektu ze współrzędnymi - nie potrzebne przy osmData

  // sprawdzenie zawartości obiektu ze współrzędnymi
  // if (formik.values.lokalizacja == null) { // obiektu z danymi nie ma
  //   zipFieldRef.current.value = ""; // wyczyszczenie pola, konieczne z ustawieniem błędu
  //   formik.setFieldError('acInput', 'err'); // konieczne jest też ustawienie błędu
  //   return; // opuszczenie procedury
  // }

  // === PRZYGOTOWANIE OBIEKTU Z DANYMI LOKALIZACYJNYMI:

  /* kształt obiektu po API:
    const locationGeoTmp = {
      type: 'Point',
      coordinates: [odebranyObiekt.lokalizacja.lng, odebranyObiekt.lokalizacja.lat]    // tu jest błąd
    }

    const miejsceTmp = {
      zip: odebranyObiekt.lokalizacja.zip,
      city: odebranyObiekt.lokalizacja.city
    }
  */