/* 
  celem tego komponentu jest ustawienie preview: URL.createObjectURL(file),
  tam problem był że typu object, ale na any już zadziałało,
  później to jest bezpośrednio przekazywane do parametru src croppera w parent component

  cropperSrcImage = {image[0].preview}  - tak później jest to wywoływane, bierzemy tylko 1-wszy plik
  a przez input to ta metoda z readerem w onChange oraz przypisanie: 
  cropperSrcImage = {image}
*/

import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Icon } from 'semantic-ui-react';

interface IProps {
  setFiles: (files: object[]) => void;
}

const dropzoneStyles = {
  border: 'dashed 3px',
  borderColor: '#eee',
  borderRadius: '5px',
  paddingTop: '19px', // przesuwa w dół linię tekstu "kliknij i upuść ..."
  textAlign: 'center' as 'center',
  height: '70px', // wymiar w pionie
  width: '400px'
};

const dropzoneActive = {
  borderColor: 'green'
};

const Dropzone: React.FC<IProps> = ({ setFiles }) => {

  // zdarzenie Drop
  // nie wiem do czego to jest
  // zapisanie pliku oraz obiektu URL,
  // w map zmienione file: object na file: any
  const onDrop = useCallback(acceptedFiles => {

    setFiles(
      acceptedFiles.map((file: any) =>
        Object.assign(file, {
          imageUrlObject: URL.createObjectURL(file)
        })
      )
    );
  }, [setFiles]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      {...getRootProps()}
      style={
        isDragActive ? { ...dropzoneStyles, ...dropzoneActive } : dropzoneStyles
      }
    >
      <input {...getInputProps()} />
      <Icon name='upload' size='big'style={{position: 'relative', top: '-11px'}}/>
      <span>Kliknij lub upuść plik</span>
    </div>
  );
};

export default Dropzone;