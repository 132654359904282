import car_brands from "../../common/data/select-options/car_brands";
import IOwnAdvForDisplay from "../ownAdvModelForDisplay";

interface IOwnAdvFromDb {
  _id: string,
  producent: number,
  producent_inny: string,
  model: string,
  cena: number,
  miejsce: any
}

function addIntegerSeparators(x, separator) {
  x += '';
  if (!separator) return x;
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x)) {
    x = x.replace(rgx, '$1' + separator + '$2');
  }
  return x;
}

const convertProducent = (arg: Number): string => {
  const index = car_brands.findIndex((obj) => obj.value === arg);

  if (index === -1) // musi być też ten warunek, gdyby argument był błędny
    return '[!arg]';
    
  const object = car_brands[index];

  return object.text;
}

const convertOwnAdvsFromDb = (argArray: IOwnAdvFromDb[]) => {

  // to jest cała tablica tych ogłoszeń ! - musi być pętla

  const result: IOwnAdvForDisplay[] = [];  
  let object: IOwnAdvFromDb;

  for (object of argArray) {

    let producentTmp: string;
    if (object.producent_inny !== '')
      producentTmp = object.producent_inny;
    else 
      producentTmp = convertProducent(object.producent);

    const convertedObject: IOwnAdvForDisplay = {
      _id: object._id,
      producent: producentTmp,
      model: object.model,
      cena: addIntegerSeparators(object.cena, ' ') + ' zł',
      lokalizacja: object.miejsce.city
    }  

    result.push(convertedObject);

  } // ~for

  return result;

}

export default convertOwnAdvsFromDb;