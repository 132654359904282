/* cała forma w render prop, według odcinka 2/11 */

import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';  // useHistory już nie jest aktualne

import { FormikProvider, useFormik } from "formik"; // bez form nie łapie submit
import {
  Form,
  Grid,
  Message,
  Segment
} from "semantic-ui-react";

import FormikControl from "../../common/form-controls/FormikControl";
import FormikButton from "../../common/form-controls/FormikButton";

import * as Yup from "yup";
import { Users } from '../../api/agent';

// import convertError from "../../common/form-controls/ConvertError"

const RegisterForm: React.FC<RouteComponentProps> = ({history}) => {

  const [takenLogins, setTakenLogins] = useState(new Array<string>()); // do walidacji YUP
  const [takenEmails, setTakenEmails] = useState(new Array<string>());

  function handleFormSubmit(values: any) {

    // konwersja to lower case
    const loginLowerTmp = values.login.toLowerCase();
    const emailLowerTmp = values.email.toLowerCase();

    values.login = loginLowerTmp;
    values.email = emailLowerTmp; 

    Users.register(values)
    .then(function (response) {
      // 200 ok - tzn. udało się
      // console.log(response);

      history.push({
        pathname: '/msg',
        state: {
          type: 'success',
          message: response
        }
      });

    })
    .catch(function (error) {

        formik.setSubmitting(false); // KONIECZNE, odblokowanie przycisku do następnej próby

        if (error.response === undefined) // brak połączenia z serwerem, musi być na samej górze
        {
          history.push({
            pathname: '/msg',
            state: {
              type: 'error',
              message: 'Wystąpił błąd połączenia z serwerem.'
            }
          });
        }

        if (error.response.status === 409 && error.response.data.message === 'login') // conflict
        {
          formik.setFieldError("login", "podany login jest zajęty"); // nadpisuje 409 do pola
          setTakenLogins(oldArray => [...oldArray, formik.values.login]); // tak zeby ten błąd nie był wygaszony przy blur
        }

        if (error.response.status === 409 && error.response.data.message === 'email') // conflict
        {
          // email zajęty
          formik.setFieldError("email", "podany adres email był rejestrowany"); // nadpisuje 409 do pola
          setTakenEmails(oldArray => [...oldArray, formik.values.email]); // tak zeby ten błąd nie był wygaszony przy blur       
        }

        if ((error.response.status) && (error.response.status === 424)) {
          // console.log(error);
          history.push({
            pathname: '/msg',
            state: {
              type: 'error',
              message: error.response.data.message
            }
          });
        }

      });
  }

  const formik = useFormik({
    // 3 args
    initialValues: {
      login: "",
      email: "",
      password: "",
      retypedPassword: "",
      rulesChecked: false,
      form_error: false    /* komunikat zbiorczy */
    },
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
    validationSchema: Yup.object({
      login: Yup.string().notOneOf(takenLogins, 'podany login jest zajęty').required('').min(3, 'minimum 3 znaki').max(20, 'maksymalnie 20 znaków').matches(/^[a-z0-9.]+$/, 'wymagane znaki a-z 0-9 .'),
      email: Yup.string().notOneOf(takenEmails, 'podany adres email był rejestrowany').required('').email(' '),  /* musi być pusty lancuch */
      password: Yup.string().required('').max(100, 'maksymalnie 100 znaków').matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/, 'wymagane 8 znaków, w tym litera i cyfra'),
      retypedPassword: Yup.string().required('').oneOf([Yup.ref("password")], "hasła są różne"),
      rulesChecked: Yup.bool().oneOf([true], 'error') /* jak nie było 'error' to był błąd '', co w warunkach dawało false i nie działało poprawnie */
      /* rulesChecked: Yup.bool().oneOf([true], '') - reguła dla starego checkbox */
    }),
  });

  return (
    <Grid textAlign="center" verticalAlign="middle" style={{margin: '0px', height: "100%" }}>
      <Grid.Column mobile={14} tablet={12} computer={8} style={{maxWidth: 750}}>
        <Segment clearing>
          <FormikProvider value={formik}>
            <div>
              <Message attached header="Formularz rejestracji" />
              <Form
                onSubmit={formik.handleSubmit}
                className="attached fluid segment"
              >
                <br />
                <FormikControl
                  control="input"
                  name="login"
                  placeholder="login"
                  icon="user"
                  iconPosition="left"
                />
                <br />
                <FormikControl
                  control="input"
                  name="email"
                  placeholder="e-mail"
                  icon="mail"
                  iconPosition="left"
                />
                <br />
                <FormikControl
                  control="input"
                  name="password"
                  placeholder="hasło"
                  icon="key"
                  iconPosition="left"
                  type="password"
                />
                <br />
                <FormikControl
                  control="input"
                  name="retypedPassword"
                  placeholder="powtórzone hasło"
                  icon="key"
                  iconPosition="left"
                  type="password"
                />
                <br />
                {/* akceptuję regulamin - 2 divy w lini */}
                <div className="parent">
                  <div className="child">
                    <FormikControl
                      control="radix_checkbox"
                      name="rulesChecked" /* musi pokrywać się tym co w Yup */
                      label="akceptuję"
                      default_checked={false}
                    />
                  </div>
                  <div className="child" style={{ fontSize: 26, paddingTop: 2 }}>
                    <a
                      href="../assets/regulamin.txt"
                      target="_blank"
                      
                    >
                      &nbsp;&nbsp;regulamin
                    </a>
                  </div>
                </div>
                {/* ~akceptuję regulamin - 2 divy w lini */}
                

                {/* <div className="ui checkbox">
                  <input type="checkbox" name="example" />
                  <label>Make my profile visible</label>
                </div>
                <div className="ui checkbox">
                  <FormikControl 
                    control='checkbox'
                    name='rulesChecked'
                    label='akceptuję regulamin'
                  />
                </div> */}

                <br /><br/>

                <FormikButton
                  text="Załóż konto"
                  icon="mail"
                  name="submit1"
                  type="submit"
                  positive={true}
                  fluid={true}
                />
              </Form>
            </div>
          </FormikProvider>
        </Segment>
      </Grid.Column>
    </Grid>
  ); /* message */
};

export default RegisterForm;
