 /* 

  false - nie wyświetlać błędu
  true - wyświetlać podświetlenie
  text - wyświetlać z label

*/
 
const convertErrorBoolean = (touched, error) => {
  if (!touched)
    return false; /* nie wyświetlać bez tego wszystkie pola się zapalają od razu */

  return (error === '' || error === ' ') ? true :  false;  /* ' ' do e-mail */
}

export default convertErrorBoolean