/* SCENARIUSZE: funkcjonalność, testy: 
  + błędny link aktywacyjny tj /a/b
  + już aktywowane wcześniej, 403, spr po loginie -> przekierowanie info <- // bo później kod hash i tak jest kasowany
  + kod hash błędny (tj. niezogdny z tym w bazie)
  + aktywacja pomyślna
*/

import React, { useEffect } from 'react';

import { Icon } from 'semantic-ui-react'
import { Users } from '../../api/agent';

const ActivateAccountPage = (props: any) => {

  /* wysłanie axiosem tych parametrów, przez post */

  useEffect(() => {  // component did mount    
    const userId = props.match.params.userId;
    const hashCode = props.match.params.hashCode;

    // axios
    Users.activate(userId, hashCode)
    .then(function (response) {
      // 200 ok - tzn. udało się

      props.history.push({
        pathname: '/msg',
        state: {
          type: 'success',
          message: response
        }
      }); 
      return;
    })  // ~ then
    .catch(function (error) {
      // console.log(error);

      if (error.response === undefined) // brak połączenia z serwerem, musi być na samej górze
      {
        props.history.push({
          pathname: '/msg',
          state: {
            type: 'error',
            message: 'Wystąpił błąd połączenia z serwerem.'
          }
        });
      } // ~ if

      if (error.response.status === 401) // unauthorized , 403 dla nie aktywowane
      {
        props.history.push({
          pathname: '/msg',
          state: {
            type: 'error',
            message: error.response.data.message
          }
        });
      } // ~if

      if (error.response.status === 403) // już aktywowane
      {
        props.history.push({
          pathname: '/msg',
          state: {
            type: 'info',
            message: error.response.data.message
          }
        });
      } // ~if
      
    }); // ~ axios
  }, []); // ~ component did mount
 

  return (
    <div>
      <Icon name='asterisk' loading />aktywacja konta ...
    </div>
  )
}

export default ActivateAccountPage